import { Button, Divider } from "@components";
import React, { FC } from "react";
import Style from "./Nav.module.scss";

interface IProps {
  className?: string;
}

const links: {
  title: string;
  href: string;
}[] = [
  {
    title: "О регионе",
    href: "#about",
  },
  {
    title: "Инвестпроекты",
    href: "#projects",
  },
  {
    title: "Контакты",
    href: "#contacts",
  },
];

const Nav: FC<IProps> = ({ className }) => (
  <div className={`${Style.Nav} ${className}`}>
    <p className={Style.Title}>Навигация:</p>
    <ul className={Style.List}>
      {links.map((link) => (
        <li key={link.title} className={Style.Item}>
          <Button view="pale" href={link.href}>
            {link.title}
          </Button>
        </li>
      ))}
    </ul>
    <Divider className={Style.Divider} />
    <Button className={Style.IndexLink} view="pale" href={"/"}>
      На главную
    </Button>
  </div>
);

export default Nav;
