import { useAppDispatch, useAppSelector } from "@hooks";
import { ModalRoot } from "@components";
import React, { FC, useState } from "react";
import { closeModal } from "@reducers/UI/ModalsSlice";
import Email from "./components/Email";
import Password from "./components/Password";

const Auth: FC = () => {
  const name = "auth";
  const [email, setEmail] = useState<string>();
  const dispatch = useAppDispatch();
  const Modals = useAppSelector((state) => state.UI.Modals.data);

  const modalCloseHandler = (): void => {
    dispatch(closeModal({ name }));
  };

  return (
    <ModalRoot
      title={"Вход / Регистрация"}
      open={Modals[name]?.isOpen ?? false}
      onClose={modalCloseHandler}
    >
      {!email ? (
        <Email name={name} setEmail={setEmail} />
      ) : (
        <Password email={email} setEmail={setEmail} />
      )}
    </ModalRoot>
  );
};

export default Auth;
