import { createSlice } from "@reduxjs/toolkit";
import Models from "@models";
import RequestStatus from "@utils/RequestStatus";

const initialState: Models.GenericState<Models.Config> = {
  data: {
    languages: ["ru", "en"],
    startAt: "01-20-2022",
    endAt: "05-20-2022",
    networkingDays: [],
    privacyPolicy: { ru: "", en: "" },
    isRegistrationOpen: true,
    defaultCountry: 1,
    isNetworkingOpen: true,
    isCutawaysOpen: true,
    isEventsOpen: true,
    defaultOffset: 3,
  },
  status: RequestStatus.Waiting,
  error: "",
};

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {},
});

export const {} = configSlice.actions;

export default configSlice.reducer;
