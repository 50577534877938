import React, { FC } from "react";
import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
} from "@mui/material";
import Style from "./LabeledControl.module.scss";

interface IProps {
  counter?: number;
  control: React.ReactElement<any, any>;
  label: string | React.ReactElement;
  name: string;
  className?: string;
  helperText?: string;
  error?: boolean;
  onChange?: (event: React.SyntheticEvent, checked: boolean) => void;
  value?: unknown;
  controlLabelProps?: Partial<FormControlLabelProps>;
}

const LabeledControl: FC<IProps> = (props) => {
  const {
    counter,
    label,
    name,
    helperText,
    error,
    controlLabelProps,
    control,
    className,
    onChange,
  } = props;

  return (
    <FormControl className={Style.FormControl} error={error}>
      <FormControlLabel
        control={control}
        classes={{ root: Style.Root, label: Style.LabelMUI }}
        name={name}
        className={className}
        onChange={onChange}
        label={
          <div className={Style.LabelWrapper}>
            <span className={`${Style.Label} ${error ? Style.Error : ""}`}>{label}</span>
            {counter && <span className={Style.Counter}>{counter}</span>}
          </div>
        }
        {...controlLabelProps}
      />
      <FormHelperText classes={{ root: Style.Helper, error: Style.Error }}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default LabeledControl;
