import React, { FC } from "react";
import { Button as MUIButton, ButtonProps } from "@mui/material";
import Style from "./Button.module.scss";
import { Link } from "react-router-dom";

interface IProps extends Partial<ButtonProps> {
  view?: "contained" | "outlined" | "pale";
  to?: string;
  className?: string;
  target?: "_blank";
}

const Button: FC<IProps> = (props) => {
  const { children, className, view = "contained", to } = props;
  const classes = [Style.Button, className];
  let MUIvariant: "outlined" | "contained" | "text" | undefined;

  switch (view) {
    case "contained":
      MUIvariant = "contained";
      classes.push(Style.Contained);
      break;

    case "outlined":
      MUIvariant = "outlined";
      classes.push(Style.Outlined);
      break;

    case "pale":
      MUIvariant = "contained";
      classes.push(Style.Pale);
      break;

    default:
      break;
  }

  return (
    <MUIButton
      component={to ? Link : "button"}
      to={to}
      classes={{
        root: Style.Root,
        sizeLarge: Style.Large,
        sizeMedium: Style.Medium,
        disabled: Style.Disabled,
        startIcon: Style.StartIcon,
        endIcon: Style.EndIcon,
      }}
      variant={MUIvariant}
      {...props}
      className={classes.join(" ")}
    >
      {children}
    </MUIButton>
  );
};

export default Button;
