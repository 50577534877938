import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import io from "socket.io-client";
import jwt_decode from "jwt-decode";
import Style from "./Chat.module.scss";
import dayjs from "@utils/dayjs";
import chatIcon from "@img/chat-send.svg";
import Models from "@models";
import { ChatMessage } from "@app/models";

enum BroadcastStatus {
  Past,
  Live,
  Future,
}

interface IProps {
  broadcast_id: number;
  token: string;
  broadcastStatus: BroadcastStatus;
}

const Chat: FC<IProps> = ({ broadcast_id, token, broadcastStatus }) => {
  const [stateSocket, setStateSocket] = useState<any>(null);
  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<Models.ChatMessage[]>([]);
  // const [status, setStatus] = useState("");
  const [roomId, setRoomId] = useState<number>(0);
  const [jwtUser, setJwtUser] = useState<any>(null);

  useEffect(() => {
    console.log("TRY TO CONNECT TO CHAT SERVER");
    setJwtUser(jwt_decode(token));
    const socket = io(process.env.REACT_APP_API_URL_CHAT + "");
    socket.on("connected", () => {
      socket.emit("login", {
        token,
        type: "broadcast",
        broadcast_id,
      });
    });

    socket.on("joined", (data: { room_id: number }) => {
      setRoomId(data.room_id);
      socket.emit("get-messages", { room_id: data.room_id });
    });

    socket.on("add-history", (data: any) => {
      setMessages((prevState) => data.messages.concat(prevState));
      setTimeout(() => {
        const element = document.getElementById("chat-messages");
        if (element !== null) {
          element.scrollTop = element.scrollHeight;
        }
      }, 100);
    });

    socket.on("new-message", (data: ChatMessage) => {
      setMessages((prevState): any[] => {
        const tempMessages = [...prevState];
        tempMessages.push(data);
        return tempMessages;
      });
      setTimeout(() => {
        const element = document.getElementById("chat-messages");
        if (element !== null) {
          element.scrollTop = element.scrollHeight;
        }
      }, 100);
    });

    socket.on("message-deleted", ({ message_id }) => {
      // console.log("TRY TO REMOVE ", message_id);
      // console.log("AFTER REMOVING: ", messages);

      setMessages((prevState) => {
        let tempMessages = [...prevState];
        tempMessages = tempMessages.filter((message) => message.id !== message_id);
        return tempMessages;
      });
      setTimeout(() => {
        const element = document.getElementById("chat-messages");
        if (element !== null) {
          element.scrollTop = element.scrollHeight;
        }
      }, 100);
    });

    setStateSocket(socket);

    return () => {};
  }, []);

  const deleteMessage = (message_id: number) => {
    stateSocket.emit("delete-message", { message_id, room_id: roomId });
  };

  return (
    <>
      <div className={Style.Chat}>
        <div className={Style.ChatHeader}>Чат</div>
        <div className={Style.ChatBody}>
          <div className={Style.ChatBodyContent} id="chat-messages">
            {/* <div className={Style.ChatMessage}>
              <div className={Style.Query}>
                <div className={Style.QueryApplicant}>
                  Я Мария Митохина —{" "}
                  <span className={Style.QueryApplicantPosition}>
                    менеджер Anex Tour, г. Самара.
                  </span>
                </div>
                <div className={Style.QueryApplicantContacts}>
                  <div className={Style.QueryApplicantContactsTitle}>Мои контакты:</div>
                  <a className={Style.QueryApplicantContact} href="mailto:mitochina@mail.ru">
                    mitochina@mail.ru
                  </a>
                  <a className={Style.QueryApplicantContact} href="tel:+7 999 123-14-11">
                    +7 999 123-14-11
                  </a>
                </div>
                <hr className={Style.QuerySeparator} />
                <div className={Style.QueryDetails}>
                  <div className={Style.QueryDetailsTitle}>Хочу отправить туристов:</div>
                  <div className={Style.QueryDetailsGroup}>
                    <div className={Style.QueryDetailsItem}>
                      <span className={Style.QueryDetailsHint}>Взрослых:</span> 2
                    </div>
                    <div className={Style.QueryDetailsItem}>
                      <span className={Style.QueryDetailsHint}>Детей:</span> 1
                    </div>
                  </div>
                  <div className={Style.QueryDetailsGroup}>
                    <div className={Style.QueryDetailsItem}>
                      <span className={Style.QueryDetailsHint}>Заезд:</span> 10 декабря 2021
                    </div>
                    <div className={Style.QueryDetailsItem}>
                      <span className={Style.QueryDetailsHint}>Выезд:</span> 12 декабря 2021
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {messages.map((message: any, index: number) => (
              <>
                <div
                  className={[
                    Style.ChatMessage,
                    message?.user?.id === jwtUser?.context.user.id && Style.Self,
                  ].join(" ")}
                >
                  {message.message}

                  {jwtUser?.context.access.admin && (
                    <>
                      <hr />
                      <Link
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          deleteMessage(message.id);
                        }}
                        className={Style.DeleteButton}
                      >
                        Delete
                      </Link>
                    </>
                  )}
                </div>
                {messages[index + 1]?.user?.id === message?.user?.id ? (
                  <></>
                ) : (
                  <div
                    className={[
                      Style.ChatAuthor,
                      message?.user?.id === jwtUser?.context.user.id && Style.Self,
                    ].join(" ")}
                  >
                    <div
                      className={[
                        Style.ChatAuthorName,
                        jwtUser?.context.access.admin && Style.AdminName,
                      ].join(" ")}
                    >{`${message.user.firstname} ${message.user.lastname}`}</div>
                    <div className={Style.ChatAuthorDate}>
                      {dayjs(message.created_at).tz("Europe/Moscow").format("HH:mm | DD MMMM YYYY")}
                    </div>
                  </div>
                )}
              </>
            ))}
            {/* <div className="meeting__services">
              <a href="#">
                <b>Алексей Венгин</b>
              </a>{" "}
              подключил (-а){" "}
              <a href="#">
                <b>Арсений Шабанов</b>
              </a>
              ,{" "}
              <a href="#">
                <b>Светлана Смирнова</b>
              </a>
            </div> */}
          </div>
        </div>
        <div className={Style.ChatFooter}>
          {/* <Button color="blue" circled>
              Send file <img src={require("@public/images/icons/clip-icon.svg")} alt="" />
            </Button> */}
          {/* <a className="meeting__chat-btn" href="#">
              Отправить визитку
              <img
                className="meeting__chat-icon"
                src={require("@public/images/btn-file.svg")}
                alt=""
              />
            </a> */}
          {broadcastStatus === BroadcastStatus.Live && (
            <form
              onSubmit={(e) => {
                const tempMessage = message;
                stateSocket.emit("send-message", { room_id: roomId, message: tempMessage });
                setMessage("");
                e.preventDefault();
              }}
            >
              <div className={Style.ChatFooterGroup}>
                <input
                  className={Style.ChatInput}
                  type="text"
                  placeholder="Напишите сообщение..."
                  value={message}
                  required
                  onChange={(e) => setMessage(e.target.value)}
                />
                <button className={Style.ChatSend} type="submit">
                  <img src={chatIcon} alt="" />
                </button>
              </div>
            </form>
          )}
        </div>
        {/* <Chat
                  user_id={this.props.Store.Auth.data.id}
                  user_name={
                    this.props.Store.Auth.data.firstName + " " + this.props.Store.Auth.data.lastName
                  }
                /> */}
      </div>
    </>
  );
};

export default Chat;
