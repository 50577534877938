import { useState } from "react";

type IConfig<T> = {
  [K in keyof T]: {
    validators: { func: (data: T[K]) => boolean; error: string }[];
    value: T[K];
  };
};

type IFields<T> = {
  [K in keyof T]: IField<T[K]>;
};

interface IField<K> {
  value: K;
  isValid?: boolean;
  error?: string;
}

const useForm = <T>(
  config: IConfig<T>
): [
  fields: IFields<T>,
  setValue: (name: keyof IConfig<T>, data: T[keyof T]) => void,
  submitForm: () => void,
  resetFields: () => void
] => {
  type currentConfig = IConfig<T>;
  type currentFields = IFields<T>;
  const configKeys = Object.keys(config) as (keyof currentConfig)[];

  const initialFields: () => currentFields = () => {
    const tempFields = {} as currentFields;

    for (const name of configKeys) {
      tempFields[name] = { value: config[name].value };
    }
    return tempFields;
  };

  const [fields, setFields] = useState<currentFields>(initialFields);
  const fieldsKeys = Object.keys(fields) as (keyof currentFields)[];

  const innerValidate = (name: keyof currentConfig, data: T[keyof T]): [boolean, string] => {
    for (const validator of config[name].validators) {
      if (!validator.func(data)) {
        return [false, validator.error];
      }
    }
    return [true, ""];
  };

  const innerFormValidate = () => {
    const tempFields = { ...fields } as currentFields;

    for (const name of configKeys) {
      const [isValid, error] = innerValidate(name, fields[name].value);

      tempFields[name] = { ...fields[name], isValid, error };
    }

    setFields({ ...tempFields });
  };

  const setValue = (name: keyof currentConfig, data: T[keyof T]): void => {
    const [isValid, error] = innerValidate(name, data);

    setFields({
      ...fields,
      [name]: { ...fields[name], value: data, isValid, error },
    });
  };

  const submitForm = (): void => {
    innerFormValidate();

    const invalidFields: any[] = [];

    for (const field of fieldsKeys) {
      if (fields[field].isValid !== true) {
        invalidFields.push(field);
      }
    }
    if (invalidFields.length > 0) {
      throw new Error(`Форма содержит ошибки`);
    }
  };

  const resetFields = (): void => {
    const tempObj = { ...fields } as currentFields;

    configKeys.forEach((key) => {
      tempObj[key] = { ...tempObj[key], value: Array.isArray(tempObj[key].value) ? [] : "" };
    });

    setFields(tempObj);
  };

  return [fields, setValue, submitForm, resetFields];
};

export default useForm;
