import { broadcastSubscribe } from "@app/store/reducers/BroadcastsSlice";
import store from "@store/index";
import { addNotification, closeNotification } from "@reducers/UI/NotificationsSlice";

const SignUp = (id: number, isSubscribed: boolean): Promise<any> => {
  store.dispatch(
    addNotification({
      key: "subscribeWaiting",
      message: isSubscribed ? "Отписка от трансляции" : "Запись на трансляцию...",
      options: { persist: true },
    })
  );

  const response = store.dispatch(broadcastSubscribe(id)).then(() => {
    store.dispatch(closeNotification("subscribeWaiting"));
    store.dispatch(
      addNotification({
        message: isSubscribed ? "Вы отписались от трансляции" : "Вы записаны на трансляцию!",
        options: { variant: "success" },
      })
    );
  });

  return response;
};

export default SignUp;
