import Validators from "@utils/Validators";
import { Button, Checkbox, Input, LabeledControl } from "@components";
import React, { FC, useState } from "react";
import { Link } from "react-router-dom";

import Style from "./Password.module.scss";
import { useAppDispatch, useForm } from "@hooks";
import Models from "@models";
import { closeAllModals, closeModal } from "@reducers/UI/ModalsSlice";
import Routes from "@utils/Routes";
import Services from "@services";
import { addNotification } from "@reducers/UI/NotificationsSlice";

interface IProps {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string | undefined>>;
}

interface IForm {
  password: string;
}

const Password: FC<IProps> = ({ email, setEmail }) => {
  const dispatch = useAppDispatch();

  const [fields, setValue, submitForm] = useForm<IForm>({
    password: {
      validators: [Validators.required()],
      value: "",
    },
  });
  const [isPasswordShow, setIsPasswordShow] = useState<boolean>(false);

  const changeHandler: Models.InputChangeHandler = (e) => {
    setValue(e.target.name as keyof IForm, e.target.value);
  };

  const formSubmitHandler: Models.SubmitHandler = async (e) => {
    e.preventDefault();

    try {
      submitForm();
      Services.Auth.SignIn(email, fields.password.value).then(() => {
        dispatch(closeAllModals());
        setTimeout(() => {
          setEmail(undefined);
        }, 500);
      });
    } catch (error) {
      dispatch(
        addNotification({ message: "Неверные данные для входа", options: { variant: "error" } })
      );
    }
  };

  const backBtnClickHandler = (): void => {
    setEmail(undefined);
  };

  return (
    <form className={Style.Form} onSubmit={formSubmitHandler}>
      <div className={Style.Header}>
        <p className={Style.Title}>Логин:</p>
        <p className={Style.Email}>{email}</p>
      </div>
      <div className={Style.Body}>
        <Input
          label="Введите пароль"
          name="password"
          type={isPasswordShow ? "text" : "password"}
          fullWidth
          error={fields?.password.isValid === false}
          helperText={fields?.password.error}
          value={fields.password.value}
          onChange={changeHandler}
        />
        <LabeledControl
          control={<Checkbox />}
          label={"Показать пароль"}
          name={"passwordview"}
          className={Style.Checkbox}
          onChange={() => {
            setIsPasswordShow(!isPasswordShow);
          }}
        />
      </div>
      <div className={Style.Footer}>
        <Button view="pale" onClick={backBtnClickHandler}>
          Назад
        </Button>
        <Button type="submit">Далее</Button>
        <Link
          className={Style.PasswordLink}
          to={Routes.Auth.PasswordReset.path}
          onClick={() => {
            dispatch(closeModal({ name: "auth" }));
          }}
        >
          Не помню пароль
        </Link>
      </div>
    </form>
  );
};

export default Password;
