import { FC } from "react";
import Style from "./Contact.module.scss";

interface IProps {
  title: string;
  users: { name: string; email: string }[];
}

const Contact: FC<IProps> = ({ title, users }) => (
  <li className={Style.Contact}>
    <p className={Style.Title}>{title}</p>
    <div className={Style.Body}>
      {users.map((user, index) => (
        <div className={Style.User} key={index}>
          <p className={Style.Name}>{user.name}</p>
          <a className={Style.Email} href={`mailto:${user.email}`}>
            {user.email}
          </a>
        </div>
      ))}
    </div>
  </li>
);

export default Contact;
