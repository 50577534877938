import { FormControl, FormHelperText, TextField } from "@mui/material";
import React, { FC } from "react";
import PhoneInputRoot, { PhoneInputProps } from "react-phone-input-material-ui";
import "react-phone-input-material-ui/lib/style.css";
import Style from "./PhoneInput.module.scss";

interface IProps extends Partial<PhoneInputProps> {
  required?: boolean;
  error?: boolean;
  helperText?: string;
}

const PhoneInput: FC<IProps> = (props) => {
  const { helperText, error, required = false } = props;

  return (
    <FormControl className={Style.FormControl}>
      <PhoneInputRoot
        component={TextField}
        containerClass={Style.Wrapper}
        inputProps={{
          variant: "filled",
          required,
          label: "Телефон",

          InputProps: {
            disableUnderline: true,
            error,

            classes: {
              root: Style.InputControl,
              input: Style.Input,
              focused: Style.Focused,
              error: Style.Error,
            },
          },
        }}
        {...props}
      />
      <FormHelperText error={error} classes={{ root: Style.HelperText, error: Style.Error }}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default PhoneInput;
