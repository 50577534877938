import { fetchBroadcasts } from "@app/store/reducers/BroadcastsSlice";
import { useAppDispatch, useAppSelector } from "@hooks";
import { FC, useEffect } from "react";
import Style from "./Schedule.module.scss";
import Day from "./Schedule/Day";

const Schedule: FC = () => {
  const dispatch = useAppDispatch();
  const Broadcasts = useAppSelector((state) => state.Broadcasts.list.data);

  useEffect(() => {
    dispatch(fetchBroadcasts());
  }, []);

  return (
    <section className={Style.Root}>
      <h2 className={Style.Title} id="schedule">
        Деловая программа
      </h2>
      <div className={Style.Days}>
        {Broadcasts?.map((day) => (
          <Day key={day.date} date={day.date} broadcasts={day.broadcasts} />
        ))}
      </div>
    </section>
  );
};

export default Schedule;
