import { Block, Button } from "@components";
import React, { FC } from "react";
import Style from "./Header.module.scss";

interface IProps {
  isFilterShow: boolean;
  setIsFilterShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header: FC<IProps> = ({ isFilterShow, setIsFilterShow }) => (
  <Block className={Style.Root}>
    <h1 className={Style.Title}>Каталог инвестпроектов</h1>
    <p className={Style.Description}>
      Используйте фильтры для поиска проектов в различных регионах РФ
    </p>
    <Button
      className={Style.FilterBtn}
      view="pale"
      endIcon={<i className="icon-filter" />}
      onClick={() => {
        setIsFilterShow(!isFilterShow);
      }}
    >
      Фильтр
    </Button>
  </Block>
);

export default Header;
