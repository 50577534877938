import { useSwiperRef } from "@hooks";
import React, { FC } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import Style from "./Slider.module.scss";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/css/virtual";
import { Avatar } from "@components";
import Models from "@models";

interface IExpert {
  sorting: number;
  moderator: boolean;
  user: Models.User;
}
interface IProps {
  experts: IExpert[];
}

const Slider: FC<IProps> = ({ experts }) => {
  const [prevEl, prevRef] = useSwiperRef<HTMLDivElement>();
  const [nextEl, nextRef] = useSwiperRef<HTMLDivElement>();

  return (
    <div className={Style.Root}>
      <div className={Style.Container}>
        <Swiper
          spaceBetween={24}
          modules={[Navigation]}
          slidesPerView={"auto"}
          navigation={{
            prevEl,
            nextEl,
            disabledClass: Style.Disabled,
          }}
        >
          {experts?.map((expert, index) => (
            <SwiperSlide className={Style.Slide} key={`slide-${index}`} virtualIndex={index}>
              <div className={Style.Expert}>
                <div className={Style.Img}>
                  <Avatar src={expert.user.image} />
                </div>
                <p className={Style.Name}>
                  {expert.user.firstName} {expert.user.lastName}
                </p>
                {expert.user.workName && <p className={Style.Company}>{expert.user.workName}</p>}

                {expert.moderator && <p className={Style.Moderator}>Модератор</p>}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div ref={prevRef} className={`${Style.Arrow} ${Style.ArrowPrev}`}>
          <i className="icon-arrow-right" />
        </div>
        <div ref={nextRef} className={`${Style.Arrow} ${Style.ArrowNext}`}>
          <i className="icon-arrow-right" />
        </div>
      </div>
    </div>
  );
};

export default Slider;
