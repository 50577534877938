import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Models from "@models";
import RequestStatus from "@utils/RequestStatus";

const initialState: Models.GenericState<{
  current: number;
  utcOffset: number;
  isUserTime: boolean;
}> = {
  data: {
    current: Date.now(),
    utcOffset: 0,
    isUserTime: false,
  },
  status: RequestStatus.Waiting,
  error: "",
};

const configSlice = createSlice({
  name: "time",
  initialState,
  reducers: {
    setTime: (state, action: PayloadAction<number>) => {
      state.data.current = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.data.utcOffset = action.payload;
    },
    setSource: (state, action: PayloadAction<boolean>) => {
      state.data.isUserTime = action.payload;
    },
  },
});

export const { setTime, setOffset, setSource } = configSlice.actions;

export default configSlice.reducer;
