import { Project } from "@components";
import React, { FC } from "react";
import Style from "./Projects.module.scss";
import Models from "@models";

interface IProps {
  title: string;
  projects: Models.Project[];
}

const Projects: FC<IProps> = ({ title, projects }) => (
  <div className={Style.Root}>
    <h2 className={Style.MainTitle}>{title}</h2>
    <div className={Style.List}>
      {projects.map((project) => (
        <Project key={project.id} data={project} />
      ))}
    </div>
  </div>
);

export default Projects;
