import Models from "@models";
import { defaultInstance } from "./instances";

export const fetch = async (id: number): Promise<any> =>
  await defaultInstance.get<Promise<Models.Broadcast>>(`/broadcast/${id}`);

export const fetchAll = async (): Promise<any> =>
  await defaultInstance.get<Promise<Models.Broadcast[]>>(`/broadcast/listWithGroup`);

export const fetchNearest = async (): Promise<any> =>
  await defaultInstance.get<Promise<Models.Broadcast[]>>(`/main/dynamic_block`);

export const subscribe = async (id: number): Promise<any> =>
  await defaultInstance.post<Promise<Models.Broadcast>>(`/broadcast/${id}/subscribe`);
