import React, { FC } from "react";
import defaultAvatar from "@img/default-avatar.svg";
import Style from "./Avatar.module.scss";

interface IProps {
  src: string | null;
}

const Avatar: FC<IProps> = ({ src }) => (
  <div className={`${Style.Avatar} ${!src && Style.Null}`}>
    <img src={src ? src : defaultAvatar} alt="" />
  </div>
);

export default Avatar;
