import { Chip, ChipProps } from "@mui/material";
import React, { FC } from "react";
import Style from "./Tag.module.scss";

interface IProps extends Partial<ChipProps> {
  type?: "blue" | "grey";
}

const Tag: FC<IProps> = (props) => {
  const { type = "blue" } = props;
  const classes: string[] = [Style.Chip, Style.Custom];

  switch (type) {
    case "blue":
      classes.push(Style.Blue);
      break;

    case "grey":
      classes.push(Style.Grey);
      break;

    default:
      break;
  }

  return <Chip classes={{ root: classes.join(" "), label: Style.Label }} {...props} />;
};

export default Tag;
