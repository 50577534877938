import React, { FC } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Virtual } from "swiper";
import Style from "./Slider.module.scss";
import sliderNextImg from "@assets/img/sliderNext.png";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/css/virtual";
import { useSwiperRef } from "@hooks";

interface IProps {
  images: string[];
}

const Slider: FC<IProps> = ({ images }) => {
  const [paginationEl, paginationRef] = useSwiperRef<HTMLDivElement>();
  const [prevEl, prevRef] = useSwiperRef<HTMLDivElement>();
  const [nextEl, nextRef] = useSwiperRef<HTMLDivElement>();

  return (
    <div className={Style.Root}>
      <Swiper
        spaceBetween={24}
        modules={[Navigation, Pagination, Virtual]}
        pagination={{
          clickable: true,
          el: paginationEl,
          type: "bullets",
          bulletClass: `${Style.Bullet}`,
          bulletActiveClass: `${Style.BulletActive}`,
        }}
        navigation={{
          prevEl,
          nextEl,
          disabledClass: Style.Disabled,
        }}
        virtual
      >
        {images.map((image, index) => (
          <SwiperSlide className={Style.Slide} key={`slide-${index}`} virtualIndex={index}>
            <img src={image} alt="" />
          </SwiperSlide>
        ))}
        <div ref={prevRef} className={`${Style.Arrow} ${Style.ArrowPrev}`}>
          <img src={sliderNextImg} alt="" />
        </div>
        <div ref={nextRef} className={`${Style.Arrow} ${Style.ArrowNext}`}>
          <img src={sliderNextImg} alt="" />
        </div>
      </Swiper>
      <div ref={paginationRef} className={Style.Pagination} />
    </div>
  );
};

export default Slider;
