import React, { FC } from "react";
import { Fade, IconButton, ModalUnstyledProps } from "@mui/material";
import { ModalUnstyled as MUIModal } from "@mui/base";
import Style from "./ModalRoot.module.scss";

interface IProps extends ModalUnstyledProps {
  title?: string;
  onClose: () => void;
}

const closeIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 6L18 18" stroke="black" strokeWidth="2" strokeLinecap="round" />
    <path d="M6 18L18 6" stroke="black" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

const Modal: FC<IProps> = (props) => {
  const { title, onClose, children } = props;

  return (
    <MUIModal
      {...props}
      onClose={onClose}
      classes={{ root: Style.Root }}
      BackdropComponent={"div"}
      closeAfterTransition
      BackdropProps={{ className: Style.Backdrop }}
      disableAutoFocus
    >
      <Fade in={props.open}>
        <div className={Style.Modal}>
          <div className={Style.Header}>
            <p className={Style.Title}>{title}</p>
          </div>

          <div className={Style.Body}>{children}</div>
          <IconButton onClick={onClose} classes={{ root: Style.Close }}>
            {closeIcon}
          </IconButton>
        </div>
      </Fade>
    </MUIModal>
  );
};

export default Modal;
