import React, { FC } from "react";
import defaultLogo from "@img/default-logo.svg";
import Style from "./Logo.module.scss";

interface IProps {
  src: string | null;
  alt?: string;
}

const Logo: FC<IProps> = ({ src, alt }) => (
  <div className={`${Style.Logo} ${!src && Style.Null}`}>
    <img src={src ? src : defaultLogo} alt={alt} />
  </div>
);

export default Logo;
