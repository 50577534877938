import Validators from "@utils/Validators";
import { Button, Container, Input, PseudoModal } from "@components";
import React, { FC, useState } from "react";
import Models from "@models";
import { useAppDispatch, useForm } from "@hooks";
import { addNotification } from "@reducers/UI/NotificationsSlice";
import Style from "./PasswordResetPage.module.scss";
import { useLocation } from "react-router-dom";
import Services from "@app/services/_Services";

interface IForm {
  email: string;
}

const PasswordResetPage: FC = () => {
  const dispatch = useAppDispatch();
  const [isPasswordReseted, setIsPasswordReseted] = useState<boolean>(false);
  const locationState = useLocation().state as { from: string };
  const [fields, setValue, submitForm] = useForm<IForm>({
    email: {
      validators: [Validators.email()],
      value: "",
    },
  });

  const changeHandler: Models.InputChangeHandler = (e) => {
    setValue(e.target.name as keyof IForm, e.target.value);
  };

  const formSubmitHandler: Models.SubmitHandler = (e) => {
    e.preventDefault();
    try {
      submitForm();
      Services.Auth.PasswordReset({ email: fields?.email.value }).then(() => {
        setIsPasswordReseted(true);
      });
    } catch (error: any) {
      dispatch(addNotification({ message: error.message, options: { variant: "error" } }));
    }
  };

  return (
    <Container type="pseudomodal">
      {!isPasswordReseted ? (
        <PseudoModal title="Сбросить пароль">
          <form noValidate onSubmit={formSubmitHandler} className={Style.Form}>
            <p className={Style.Disclaimer}>
              Пожалуйста, введите свой email — мы отправим письмо с ссылкой на сброс пароля на
              почту.
            </p>
            <Input
              type="text"
              label="E-mail"
              name="email"
              required
              error={fields?.email.isValid === false}
              helperText={fields?.email.error}
              fullWidth
              value={fields?.email.value}
              onChange={changeHandler}
            />
            <Button type="submit" className={Style.Btn}>
              Далее
            </Button>
          </form>
        </PseudoModal>
      ) : (
        <PseudoModal title="Спасибо!">
          <div className={Style.Form}>
            <p className={Style.Disclaimer}>Ссылка для сброса пароля отправлена на ваш email.</p>
            <Button to={locationState?.from ?? "/"} className={Style.Btn}>
              Вернуться
            </Button>
          </div>
        </PseudoModal>
      )}
    </Container>
  );
};

export default PasswordResetPage;
