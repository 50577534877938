import { useAppDispatch, useAppSelector } from "@hooks";
import { Avatar, Block, Button, Divider, Skeleton, Time } from "@components";
import React, { FC } from "react";
import Style from "./Schedule.module.scss";
import dayjs from "@utils/dayjs";
import RequestStatus from "@utils/RequestStatus";
import { openModal } from "@reducers/UI/ModalsSlice";

interface IProps {
  className?: string;
}

const Schedule: FC<IProps> = ({ className }) => {
  const dispatch = useAppDispatch();
  const Broadcast = useAppSelector((state) => state.Broadcasts.current.data);
  const BroadcastStatus = useAppSelector((state) => state.Broadcasts.current.status);
  const isLoading = BroadcastStatus !== RequestStatus.Success;
  const User = useAppSelector((state) => state.User.data);

  if (isLoading) {
    return <Skeleton height={650} variant="rectangular" className={className} />;
  }

  if (Broadcast?.scheduleItemList.length) {
    return (
      <Block className={`${Style.Root} ${className || ""}`}>
        <h2 className={Style.Title}>Программа</h2>
        <div className={Style.List}>
          {Broadcast?.scheduleItemList.map((item, index) => (
            <React.Fragment key={item.id}>
              <div key={item.id} className={Style.Item}>
                <div className={Style.ItemMain}>
                  <Time>{dayjs(item.startAt).tz("Europe/Moscow").format("HH:mm")}</Time>
                  <div className={Style.ItemContent}>
                    <h3 className={Style.ItemTitle}>{item.title}</h3>
                    <div
                      className={Style.ItemDesc}
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </div>
                </div>
                <div className={Style.UserList}>
                  {item.speakerList.map((speaker) => (
                    <div key={speaker.user.id} className={Style.User}>
                      <div className={Style.UserBody}>
                        <div className={Style.UserAvatar}>
                          <Avatar src={speaker.user.image} />
                        </div>
                        <p className={Style.UserName}>
                          {speaker.user.firstName} {speaker.user.lastName}
                        </p>
                        <p className={Style.UserPosition}>
                          {speaker.user.position}
                          {speaker.user.workName && speaker.user.position ? ", " : ""}
                          {speaker.user.workName}
                        </p>
                      </div>
                      {speaker.file && (
                        <Button
                          className={Style.DownloadBtn}
                          view="outlined"
                          endIcon={<i className="icon-file" />}
                          href={User ? speaker.file : ""}
                          target="_blank"
                          onClick={(e) => {
                            if (!User) {
                              e.preventDefault();
                              dispatch(openModal({ name: "auth" }));
                            }
                          }}
                        >
                          Скачать презентацию
                        </Button>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              {index !== Broadcast.scheduleItemList.length - 1 && (
                <Divider className={Style.Divider} />
              )}
            </React.Fragment>
          ))}
        </div>
      </Block>
    );
  }

  return <></>;
};

export default Schedule;
