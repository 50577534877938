import store from "@store/index";
import API from "@api";
import {
  addNotification,
  closeNotification,
  removeNotification,
} from "@reducers/UI/NotificationsSlice";

const PasswordReset = (data: { email: string }): Promise<any> => {
  store.dispatch(
    addNotification({
      key: "reseting",
      message: "Сбрасываем пароль...",
      options: { persist: true },
    })
  );
  const response: any = API.Auth.passwordReset(data)
    .then(() => {
      store.dispatch(closeNotification("reseting"));
      store.dispatch(
        addNotification({
          message: "Пароль сброшен!",
          options: { variant: "success" },
        })
      );
    })
    .catch((error) => {
      store.dispatch(removeNotification("reseting"));
      store.dispatch(
        addNotification({ message: error.response.data.error, options: { variant: "error" } })
      );
      return Promise.reject(response);
    });
  return Promise.resolve(response);
};

export default PasswordReset;
