const Routes: Record<string, any> = {
  Project: {
    path: "/project",
    pathname: "project",
  },
  Region: {
    path: "/region",
    pathname: "region",
  },

  Catalog: {
    path: "/catalog",
    pathname: "catalog",
  },
  Auth: {
    path: "/auth",
    pathname: "auth",

    SignUp: {
      path: "/auth/signup",
      pathname: "signup",
    },
    PasswordReset: {
      path: "/auth/passwordreset",
      pathname: "passwordreset",
    },
    NewPassword: {
      path: "/auth/newpassword",
      pathname: "newpassword",
    },
  },
  Broadcast: {
    path: "/broadcast",
    pathname: "broadcast",
  },
};

export default Routes;
