import React, { FC } from "react";
import Style from "./Header.module.scss";
import { Button, Container } from "@components";
import { useAppDispatch, useAppSelector } from "@hooks";
import { openModal } from "@reducers/UI/ModalsSlice";
import { addNotification } from "@app/store/reducers/UI/NotificationsSlice";
import RequestStatus from "@app/utils/RequestStatus";
import Partners from "./Partners";
import headerLogo from "@img/header-logo.svg";

const Header: FC = () => {
  const User = useAppSelector((state) => state.User.data);
  const UserStatus = useAppSelector((state) => state.User.status);
  const dispatch = useAppDispatch();

  const clickHandler = (): void => {
    if (User) {
      dispatch(
        addNotification({
          key: "alreadyAuthorized",
          message: "Вы уже авторизованы",
          options: { variant: "info" },
        })
      );
    } else {
      dispatch(openModal({ name: "auth" }));
    }
  };

  return (
    <div className={Style.Root}>
      <Container className={Style.Container}>
        <div className={Style.Main}>
          <img className={Style.Logo} src={headerLogo} alt="" />
          <div className={Style.Content}>
            <p className={Style.Date}>27–28 апреля</p>
            <Button
              size="large"
              onClick={clickHandler}
              disabled={
                UserStatus === RequestStatus.Waiting || UserStatus === RequestStatus.Pending
              }
            >
              Регистрация
            </Button>
            <h1 className={Style.TitleBlock}>
              <span className={Style.Title}>Онлайн-форум</span>
              <span className={Style.Subtitle}>
                по инвестициям <br /> в туристскую инфраструктуру
              </span>
            </h1>
          </div>
        </div>
        <Partners />
      </Container>
    </div>
  );
};

export default Header;
