import { loadUser } from "@reducers/UserSlice";
import { loadJWT } from "./../../store/reducers/UserSlice";
import store from "@store/index";
import { addNotification } from "@reducers/UI/NotificationsSlice";

const LoadUser = (): void => {
  store
    .dispatch(loadUser())
    .then((response) => {
      if (store.getState().User.data) {
        store.dispatch(loadJWT(store.getState().User.data));
      }
      return response;
    })
    .catch((error) => {
      store.dispatch(
        addNotification({ message: error.response.data.error, options: { variant: "error" } })
      );
    });
};

export default LoadUser;
