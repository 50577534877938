import { Block, Divider, ScheduleButton, Skeleton, Time } from "@components";
import Style from "./Header.module.scss";
import React, { FC } from "react";
import { useAppSelector } from "@hooks";
import dayjs from "@utils/dayjs";
import RequestStatus from "@utils/RequestStatus";

interface IProps {
  className?: string;
}

const Header: FC<IProps> = ({ className }) => {
  const Broadcast = useAppSelector((state) => state.Broadcasts.current.data);
  const BroadcastStatus = useAppSelector((state) => state.Broadcasts.current.status);
  const isLoading = BroadcastStatus !== RequestStatus.Success;

  if (isLoading) {
    return <Skeleton height={300} variant="rectangular" className={className} />;
  }

  return (
    <Block className={`${Style.Root} ${className || ""}`}>
      <div className={Style.Body}>
        <div className={Style.Header}>
          <div className={Style.HeaderTop}>
            <div className={Style.DateBlock}>
              <p className={Style.Date}>{dayjs(Broadcast?.startAt).format("DD MMMM")}</p>
              <Divider className={Style.Divider} type="vertical" color={"darkgrey"} />
              <Time>{dayjs(Broadcast?.startAt).tz("Europe/Moscow").format("HH:mm")}</Time>
            </div>
            <p className={Style.DayOfWeek}>{dayjs(Broadcast?.startAt).format("dddd")}</p>
          </div>
          <h1 className={Style.Title}>{Broadcast?.title}</h1>
        </div>
        {Broadcast?.description && (
          <p
            className={Style.Description}
            dangerouslySetInnerHTML={{ __html: Broadcast?.description }}
          />
        )}
      </div>
      <Divider className={Style.GlobalDivider} type="vertical" />
      <div className={Style.Footer}>
        {Broadcast && (
          <>
            <ScheduleButton id={Broadcast?.id} subscribersList={Broadcast?.subscriberList} />
            <p className={Style.Participants}>{Broadcast.subscriberList.length} участников</p>
          </>
        )}
      </div>
    </Block>
  );
};

export default Header;
