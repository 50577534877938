import Models from "@models";
import { defaultInstance } from "./instances";

export const fetch = async (id: number): Promise<any> =>
  await defaultInstance.get<Models.Project[]>(`/projects/${id}`);

export const fetchAll = async (data: {
  specializations: string | null;
  region: string | null;
}): Promise<any> =>
  await defaultInstance.get<Models.Project[]>(`/projects/catalog`, { params: data });
