import React, { ReactElement } from "react";

import Style from "./preloader.module.scss";

interface PropsInterface {
  rounded?: boolean;
}

const PreloaderComponent = ({ rounded = false }: PropsInterface): ReactElement => {
  const backgroundStyle = [Style.PreloaderBackground];
  if (rounded) {
    backgroundStyle.push(Style.Rounded);
  }
  return (
    <div className={Style.PreloaderWrapperFirst}>
      <div className={Style.PreloaderWrapperSecond}>
        <div className={Style.PreloaderWrapperThird}>
          <div className={backgroundStyle.join(" ")}>
            <div className={Style.Preloader}>
              <div className={Style.PreloaderDot} />
              <div className={Style.PreloaderDot} />
              <div className={Style.PreloaderDot} />
              <div className={Style.PreloaderDot} />
              <div className={Style.PreloaderDot} />
              <div className={Style.PreloaderDot} />
              <div className={Style.PreloaderDot} />
              <div className={Style.PreloaderDot} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreloaderComponent;
