import { FC, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Layout, Modals } from "@components";
import { useNotifier } from "@hooks";
import "@utils/TimeIncrement";
import Paths from "@utils/Routes";

import Home from "@pages/Home/HomePage";
import Project from "@pages/Project/ProjectPage";
import Region from "@pages/Region/RegionPage";
import Catalog from "@pages/Catalog/CatalogPage";
import SignUp from "@pages/Auth/SignUpPage";
import Broadcast from "@pages/Broadcast/BroadcastPage";
import PasswordResetPage from "@pages/Auth/PasswordResetPage";
import NewPasswordPage from "@pages/Auth/NewPasswordPage";
import Services from "./services/_Services";
// TODO: check
// import Networking from "@pages/Networking/NetworkingPage";
// import RestorePassword from "@pages/Auth/RestorePassword/RestorePasswordPage";
// import NewPassword from "@pages/Auth/NewPassword/NewPasswordPage";
// import SignUp from "@pages/Auth/SignUp/SignUpPage";
// import NetworkingSignUp from "@pages/Auth/NetworkingSignUp/NetworkingSignUp";

const App: FC = () => {
  useNotifier();
  useEffect(() => {
    Services.Auth.LoadUser();
  }, []);

  return (
    <>
      <Router>
        <Modals.Auth />
        <Routes>
          {/* index layout */}
          <Route path={"/"} element={<Layout type="index" />}>
            <Route index element={<Home />} />
          </Route>

          {/* main layout */}
          <Route path={"/"} element={<Layout />}>
            <Route path={`${Paths.Project.pathname}/:id`} element={<Project />} />
            <Route path={`${Paths.Catalog.pathname}`} element={<Catalog />} />
            <Route path={`${Paths.Catalog.pathname}`} element={<Catalog />} />
            <Route path={Paths.Auth.pathname}>
              <Route path={`${Paths.Auth.SignUp.pathname}`} element={<SignUp />} />
              <Route
                path={`${Paths.Auth.PasswordReset.pathname}`}
                element={<PasswordResetPage />}
              />
              <Route path={`${Paths.Auth.NewPassword.pathname}`} element={<NewPasswordPage />} />
            </Route>
            <Route path={`${Paths.Broadcast.pathname}/:id`} element={<Broadcast />} />
          </Route>

          {/* region layout */}
          <Route path="/" element={<Layout type="region" />}>
            <Route path={`${Paths.Region.pathname}/:id`} element={<Region />} />
          </Route>

          {/* TODO: check */}
          {/* <Route path="/networking" element={<Networking />} />
            <Route path="/pwdrestore" element={<RestorePassword />} />
            <Route path="/newpwd" element={<NewPassword />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/networkingsignup" element={<NetworkingSignUp />} /> */}
        </Routes>
      </Router>
    </>
  );
};
export default App;
