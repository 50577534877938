import Validators from "@utils/Validators";
import {
  Button,
  Checkbox,
  Container,
  Input,
  LabeledControl,
  ModalGroup,
  PseudoModal,
} from "@components";
import React, { FC, useEffect, useState } from "react";
import Models from "@models";
import { useAppDispatch, useForm } from "@hooks";
import { addNotification } from "@reducers/UI/NotificationsSlice";
import Style from "./NewPasswordPage.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import Services from "@app/services/_Services";

interface IForm {
  password: string;
  repeatedPassword: string;
}

const PasswordResetPage: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [isPasswordsSame, setIsPasswordsSame] = useState<boolean | null>(null);
  const [isPasswordShow, setIsPasswordShow] = useState<boolean>(false);
  const [isPasswordChanged, setIsPasswordChanged] = useState<boolean>(false);
  const navigate = useNavigate();
  const locationState = location.state as { from: string };
  const [fields, setValue, submitForm] = useForm<IForm>({
    password: {
      validators: [Validators.required(), Validators.lengthCheck(8)],
      value: "",
    },
    repeatedPassword: {
      validators: [Validators.required()],
      value: "",
    },
  });

  useEffect(() => {
    const token = searchParams.get("token");

    if (!token) {
      navigate(locationState.from ?? "/");
    }
  }, []);

  useEffect(() => {
    try {
      samePasswordCheck();
    } catch (error) {}
  }, [fields?.password.value, fields?.repeatedPassword.value]);

  const samePasswordCheck: () => void = () => {
    if (fields?.repeatedPassword.value) {
      if (fields?.password.value !== fields?.repeatedPassword.value) {
        setIsPasswordsSame(false);
        throw new Error("Введённые пароли отличаются");
      } else {
        setIsPasswordsSame(true);
      }
    }
  };

  const changeHandler: Models.InputChangeHandler = (e) => {
    setValue(e.target.name as keyof IForm, e.target.value);
  };

  const formSubmitHandler: Models.SubmitHandler = (e) => {
    e.preventDefault();
    const token = searchParams.get("token");

    try {
      submitForm();
      samePasswordCheck();
      Services.Auth.NewPassword(
        {
          plainPassword: {
            newPassword: fields?.password.value,
            passwordConfirm: fields?.repeatedPassword.value,
          },
        },
        token ?? ""
      ).then(() => {
        setIsPasswordChanged(true);
      });
    } catch (error: any) {
      dispatch(addNotification({ message: error.message, options: { variant: "error" } }));
    }
  };

  return (
    <Container type="pseudomodal">
      {!isPasswordChanged ? (
        <PseudoModal title="Сбросить пароль">
          <form noValidate onSubmit={formSubmitHandler} className={Style.Form}>
            <ModalGroup>
              <Input
                type={isPasswordShow ? "text" : "password"}
                label="Пароль"
                name="password"
                required
                error={fields?.password.isValid === false}
                helperText={fields?.password.error}
                fullWidth
                value={fields?.password.value}
                onChange={changeHandler}
              />
              <Input
                type={isPasswordShow ? "text" : "password"}
                label="Повторите пароль"
                name="repeatedPassword"
                required
                error={isPasswordsSame === false || fields?.repeatedPassword.isValid === false}
                helperText={
                  isPasswordsSame === false ? "Пароли отличаются" : fields?.repeatedPassword.error
                }
                fullWidth
                value={fields?.repeatedPassword.value}
                onChange={changeHandler}
              />
              <LabeledControl
                control={<Checkbox />}
                label={"Показать пароль"}
                name={"passwordShow"}
                className={Style.Checkbox}
                onChange={() => {
                  setIsPasswordShow(!isPasswordShow);
                }}
              />
            </ModalGroup>
            <Button type="submit" className={Style.Btn}>
              Далее
            </Button>
          </form>
        </PseudoModal>
      ) : (
        <PseudoModal title="Спасибо!">
          <div className={Style.Form}>
            <p className={Style.Disclaimer}>Пароль успешно изменён.</p>
            <Button to={locationState?.from ?? "/"} className={Style.Btn}>
              Вернуться
            </Button>
          </div>
        </PseudoModal>
      )}
    </Container>
  );
};

export default PasswordResetPage;
