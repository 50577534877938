import isMobilePhone from "./validators/isMobilePhone";

interface IValidator<T> {
  func: (data: T) => boolean;
  error: string;
}

const Validators = {
  required: (): IValidator<string | boolean | string[] | number> => ({
    func: (data) => {
      if (Array.isArray(data)) {
        return data.length > 0 ? true : false;
      }

      return data ? true : false;
    },
    error: "Это обязательное поле",
  }),

  email: (): IValidator<string> => ({
    func: (data) => {
      const regexp = /\S+@\S+\.\S+/;
      return regexp.test(data);
    },
    error: "Неправильный e-mail адрес!",
  }),

  phone: (): IValidator<string> => ({
    func: (data) => isMobilePhone(data),
    error: "Неправильный номер телефона",
  }),

  lengthCheck: (length: number): IValidator<string> => ({
    func: (data) => data.length >= length,
    error: `Значение слишком короткое. Должно быть не менее ${length} символов`,
  }),
};

export default Validators;
