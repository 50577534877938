import React, { FC } from "react";
import { Checkbox as MUICheckbox, CheckboxProps } from "@mui/material";
import Style from "./Checkbox.module.scss";

const icon = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" fill="white" stroke="#D2D9D9" />
  </svg>
);

const checkedIcon = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke="#FF3322" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M9.98096 4.80331C10.1545 4.98555 10.1545 5.28103 9.98096 5.46328L6.42541 9.19661C6.25184 9.37885 5.97043 9.37885 5.79687 9.19661L4.01909 7.32994C3.84552 7.1477 3.84552 6.85222 4.01909 6.66998C4.19266 6.48773 4.47406 6.48773 4.64763 6.66998L6.11114 8.20666L9.35242 4.80331C9.52599 4.62106 9.8074 4.62106 9.98096 4.80331Z"
      fill="#232226"
    />
  </svg>
);

const Checkbox: FC<CheckboxProps> = (props) => (
  <MUICheckbox {...props} classes={{ root: Style.Root }} icon={icon} checkedIcon={checkedIcon} />
);

export default Checkbox;
