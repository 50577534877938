import { Container } from "@app/components";
import React, { FC, useEffect, useState } from "react";
import Header from "./components/Header";
import Partners from "./components/Partners";
import Style from "./BroadcastPage.module.scss";
import Schedule from "./components/Schedule";
import { useAppDispatch, useAppSelector } from "@hooks";
import { fetchBroadcast } from "@app/store/reducers/BroadcastsSlice";
import { useParams } from "react-router-dom";
import ScrollToTopOnMount from "@utils/ScrollToTopOnMount";
import dayjs from "@utils/dayjs";
import Player from "./components/Player";

enum BroadcastStatus {
  Past,
  Live,
  Future,
}

const BroadcastPage: FC = () => {
  const { id } = useParams();
  const Broadcast = useAppSelector((state) => state.Broadcasts.current.data);
  const [currentBroadcastStatus, setCurrentBroadcastStatus] = useState<BroadcastStatus>(
    BroadcastStatus.Future
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      dispatch(fetchBroadcast(+id));
    }
  }, []);

  useEffect(() => {
    const broadcastPreStart: number = dayjs(Broadcast?.startAt)
      .subtract(30, "minutes")
      .diff(dayjs(), "milliseconds");

    const broadcastStart: number = dayjs(Broadcast?.startAt).diff(dayjs(), "milliseconds") + 3000;

    if (broadcastPreStart > 0 && id) {
      setTimeout(() => {
        dispatch(fetchBroadcast(+id));
      }, broadcastPreStart);
    }

    if (broadcastStart > 0 && id) {
      setTimeout(() => {
        dispatch(fetchBroadcast(+id));
      }, broadcastStart);
    }
  }, [Broadcast?.startAt]);

  useEffect(() => {
    if (
      dayjs() > dayjs(Broadcast?.startAt).clone().subtract(30, "minutes") &&
      dayjs() < dayjs(Broadcast?.endAt)
    ) {
      setCurrentBroadcastStatus(BroadcastStatus.Live);
    } else if (dayjs() < dayjs(Broadcast?.startAt).clone().subtract(30, "minutes")) {
      setCurrentBroadcastStatus(BroadcastStatus.Future);
    } else if (dayjs() > dayjs(Broadcast?.endAt)) {
      setCurrentBroadcastStatus(BroadcastStatus.Past);
    }
  }, [Broadcast]);

  return (
    <Container className={Style.Root}>
      <ScrollToTopOnMount />
      <Header className={Style.Header} />
      <Player className={Style.Player} currentBroadcastStatus={currentBroadcastStatus} />
      <Partners className={Style.Partners} />
      <Schedule className={Style.Schedule} />
    </Container>
  );
};

export default BroadcastPage;
