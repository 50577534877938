import { Divider } from "@components";
import React, { FC } from "react";
import Style from "./Partners.module.scss";
import partner11 from "@img/partners/partner-1-1.svg";
import partner12 from "@img/partners/partner-1-2.svg";
import partner13 from "@img/partners/partner-1-3.svg";
import partner21 from "@img/partners/partner-2-1.svg";
import partner22 from "@img/partners/partner-2-2.svg";
import partner31 from "@img/partners/partner-3-1.svg";

const Partners: FC = () => (
  <div className={Style.Root}>
    <div className={Style.Block}>
      <div className={Style.Group}>
        <p className={Style.GroupTitle}>Организаторы</p>
        <div className={Style.GroupList}>
          <img src={partner11} alt="" />
          <img src={partner12} alt="" />
          <img src={partner13} alt="" />
        </div>
      </div>
      <div className={`${Style.Group} ${Style.Group2}`}>
        <p className={Style.GroupTitle}>При поддержке:</p>
        <div className={Style.GroupList}>
          <img src={partner21} alt="" />
          <img src={partner22} alt="" />
        </div>
      </div>
      <Divider className={Style.Divider} />
      <div className={`${Style.Group} ${Style.Group3}`}>
        <p className={Style.GroupTitle}>Онлайн-площадка форума:</p>
        <div className={Style.GroupList}>
          <img src={partner31} alt="" />
        </div>
      </div>
    </div>
  </div>
);

export default Partners;
