import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const HashScroll: FC = () => {
  const [isLoad, setIsLoad] = useState(false);
  const { hash } = useLocation();

  window.addEventListener("load", () => {
    setIsLoad(true);
  });

  useEffect(() => {
    if (hash && isLoad) {
      const element = document.getElementById(hash.split("#").splice(1).join(""));

      setTimeout(() => {
        element?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 100);
    }
  }, [isLoad]);

  return null;
};

export default HashScroll;
