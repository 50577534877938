import { useAppDispatch, useAppSelector } from "@hooks";
import { Button, ScheduleButton } from "@components";
import React, { FC, useEffect, useState } from "react";
import Style from "./Dynamic.module.scss";
import dayjs from "@utils/dayjs";
import { fetchBroadcasts, fetchNearestBroadcasts } from "@reducers/BroadcastsSlice";
import Routes from "@utils/Routes";
import { Link } from "react-router-dom";
import declensions from "@utils/Declensions";

const Dynamic: FC = () => {
  const dispatch = useAppDispatch();
  const currentTime = useAppSelector((state) => state.Time.data.current);
  const broadcasts = useAppSelector((state) => state.Broadcasts.nearest.data);
  const nextBroadcast = broadcasts ? broadcasts[0] : null;
  const nextBroadcastDiff = dayjs(nextBroadcast?.startAt).diff(dayjs(currentTime));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isNextEventLive, setIsNextEventLive] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchNearestBroadcasts());
  }, []);

  useEffect(() => {
    const broadcastPreStart: number = dayjs(nextBroadcast?.startAt)
      .subtract(30, "minutes")
      .diff(dayjs(), "milliseconds");

    const broadcastStart: number = dayjs(nextBroadcast?.startAt).diff(dayjs(), "milliseconds");

    const broadcastEnd: number = dayjs(nextBroadcast?.endAt).diff(dayjs(), "milliseconds");

    if (broadcastPreStart > 0) {
      setTimeout(() => {
        dispatch(fetchNearestBroadcasts());
      }, broadcastPreStart);
    }

    if (broadcastStart > 0) {
      setTimeout(() => {
        dispatch(fetchNearestBroadcasts());
        dispatch(fetchBroadcasts());
      }, broadcastStart);
    }

    if (broadcastEnd > 0) {
      setTimeout(() => {
        dispatch(fetchNearestBroadcasts());
        dispatch(fetchBroadcasts());
      }, broadcastEnd);
    }
  }, [nextBroadcast?.startAt]);

  useEffect(() => {
    if (broadcasts && broadcasts?.length > 0) {
      if (dayjs() > dayjs(nextBroadcast?.startAt).clone().subtract(30, "minutes")) {
        setIsNextEventLive(true);
      } else {
        setIsNextEventLive(false);
      }
    }
  }, [broadcasts]);

  return broadcasts?.length ? (
    <div className={Style.Root}>
      <div className={Style.Main}>
        <div className={Style.Video}>
          <iframe
            src={`https://www.youtube.com/embed/${nextBroadcast?.youtubeUrlId}?autoplay=1&mute=1`}
            frameBorder="0"
            allow="accelerometer; autoplay; mute; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
        </div>
        <div className={Style.MainItem}>
          <div className={Style.MainHeader}>
            <p className={Style.Status}>
              <i className={`icon-circle  ${nextBroadcastDiff < 0 ? Style.Live : ""}`} />
              {nextBroadcastDiff > 0 ? "Скоро в эфире" : "В эфире"}
            </p>
            <p className={Style.HeaderTime}>
              {dayjs.duration(nextBroadcastDiff).days() > 0 ? (
                <span>
                  <b>{dayjs.duration(nextBroadcastDiff).days()}</b>
                  {declensions(dayjs.duration(nextBroadcastDiff).days(), ["день", "дня", "дней"])}
                </span>
              ) : (
                <></>
              )}
              {dayjs(nextBroadcast?.startAt).diff(dayjs(currentTime), "hours") > 0 ? (
                <span>
                  <b>{dayjs.duration(nextBroadcastDiff).hours()}</b>{" "}
                  {declensions(dayjs.duration(nextBroadcastDiff).hours(), ["час", "часа", "часов"])}
                </span>
              ) : (
                <></>
              )}
              {dayjs(nextBroadcast?.startAt).diff(dayjs(currentTime), "minutes") > 0 ? (
                <span>
                  <b>{dayjs.duration(nextBroadcastDiff).minutes()}</b>{" "}
                  {declensions(dayjs.duration(nextBroadcastDiff).minutes(), [
                    "минута",
                    "минуты",
                    "минут",
                  ])}
                </span>
              ) : (
                <></>
              )}
            </p>
          </div>
          <div className={Style.MainBody}>
            <div className={Style.MainContent}>
              <time className={Style.Time}>
                {dayjs(nextBroadcast?.startAt).tz("Europe/Moscow").format("HH:mm")} <sup>МСК</sup>
              </time>
              <Link
                to={`${Routes.Broadcast.path}/${nextBroadcast?.id}`}
                className={Style.Description}
              >
                {nextBroadcast?.title}
              </Link>
            </div>
            {nextBroadcast &&
              (dayjs() > dayjs(nextBroadcast?.startAt).clone().subtract(30, "minutes") ? (
                <Button
                  className={Style.Button}
                  size="large"
                  endIcon={<i className="icon-play" style={{ fontSize: "24px" }} />}
                  to={`${Routes.Broadcast.path}/${nextBroadcast?.id}`}
                >
                  Смотреть
                </Button>
              ) : (
                <ScheduleButton
                  id={nextBroadcast?.id}
                  subscribersList={nextBroadcast?.subscriberList}
                />
              ))}
          </div>
        </div>
      </div>

      <div className={Style.Side}>
        <div className={Style.SideHeader}>
          <p className={Style.Soon}>Скоро в эфире</p>
          <time className={Style.Date}>
            {broadcasts && <>{dayjs(broadcasts[1]?.startAt).format("DD MMMM")}</>}
          </time>
        </div>
        <ul className={Style.List}>
          {broadcasts?.map(
            (broadcast, index) =>
              index > 0 && (
                <li key={broadcast.id} className={Style.Item}>
                  <p className={Style.ItemHeader}>
                    {dayjs(broadcast.startAt).tz("Europe/Moscow").format("HH:mm")} <sup>МСК</sup>
                  </p>
                  <p className={Style.ItemDesc}>{broadcast.title}</p>
                </li>
              )
          )}
        </ul>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Dynamic;
