import Models from "@models";
import { Collapse } from "@mui/material";
import dayjs from "@utils/dayjs";
import React, { FC, useState } from "react";
import Broadcast from "./Broadcast";
import Style from "./Day.module.scss";

interface IProps {
  date: string;
  broadcasts: Models.Broadcast[];
}

const Day: FC<IProps> = ({ date, broadcasts }) => {
  const [isFolded, setIsFolded] = useState<boolean>(false);

  const clickHandler = (): void => {
    setIsFolded(!isFolded);
  };

  return (
    <div className={Style.Root}>
      <h3 className={Style.Title} onClick={clickHandler} id={dayjs(date).format("YYYYMMDD")}>
        {dayjs(date).format("D MMMM")}
      </h3>
      <Collapse in={!isFolded}>
        <div className={Style.List}>
          {broadcasts.map((broadcast) => (
            <Broadcast key={broadcast.id} data={broadcast} />
          ))}
        </div>
      </Collapse>
    </div>
  );
};

export default Day;
