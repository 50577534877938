import {
  FormControl,
  FormHelperText,
  InputBase,
  InputLabel,
  Select as SelectBase,
  SelectChangeEvent,
} from "@mui/material";
import React, { FC } from "react";
import Style from "./Select.module.scss";

interface IProps {
  name: string;
  label: string;
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  value: any;
  defaultValue?: string;
  onChange?: (e: SelectChangeEvent, child: React.ReactNode) => void;
}

const Select: FC<IProps> = ({
  children,
  name,
  label,
  error,
  disabled,
  required,
  fullWidth,
  helperText,
  value,
  defaultValue,
  onChange,
}) => (
  // ! closeHandler
  // const closeHandler = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>): void => {
  //   if (onClose) {
  //     onClose(e);
  //   }
  //   if (!onClose && onChange) {
  //     onChange(e, child);
  //   }
  // };

  <FormControl
    className={Style.Wrapper}
    variant="filled"
    error={error}
    disabled={disabled}
    required={required}
    fullWidth={fullWidth}
  >
    <InputLabel
      className={Style.Label}
      classes={{ focused: Style.Focused, asterisk: Style.Asterisk, error: Style.Error }}
      htmlFor={name}
    >
      {label}
    </InputLabel>
    <SelectBase
      id={name}
      name={name}
      label={label}
      value={value}
      defaultValue={defaultValue}
      input={
        <InputBase
          classes={{
            root: Style.InputControl,
            input: Style.Input,
            focused: Style.Focused,
            error: Style.Error,
          }}
        />
      }
      MenuProps={{
        classes: { root: Style.SelectMenu, paper: Style.SelectPaper },
        // disableScrollLock: true,
      }}
      classes={{ select: Style.Select }}
      onChange={onChange}
    >
      {children}
    </SelectBase>
    {helperText ? (
      <FormHelperText className={Style.Helper} classes={{ error: Style.Error }}>
        {helperText}
      </FormHelperText>
    ) : (
      <></>
    )}
  </FormControl>
);
export default Select;
