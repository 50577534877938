import ScrollToTopOnMount from "@utils/ScrollToTopOnMount";
import { Contacts, Container, Divider, Tag } from "@components";
import { FC, useEffect } from "react";
import Style from "./ProjectPage.module.scss";
import { useAppDispatch, useAppSelector } from "@hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchProject } from "@app/store/reducers/ProjectSlice";
import RequestStatus from "@utils/RequestStatus";
import { Skeleton } from "@mui/material";

const ProjectPage: FC = () => {
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.Project.data);
  const projectStatus = useAppSelector((state) => state.Project.status);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as { from: string };
  const loading = projectStatus !== RequestStatus.Success;

  useEffect(() => {
    if (id) {
      dispatch(fetchProject(+id));
    }
  }, []);

  useEffect(() => {
    if (projectStatus === RequestStatus.Error) {
      navigate(locationState?.from ?? "/");
    }
  }, [projectStatus]);

  return (
    <Container type="project">
      <ScrollToTopOnMount />
      <div className={Style.Root}>
        <div className={Style.Img}>
          {loading ? (
            <Skeleton variant="rectangular" height={"100%"} />
          ) : (
            <img src={project?.photo} alt="" />
          )}
        </div>
        <div className={Style.Main}>
          <div className={Style.Header}>
            <h1 className={Style.Title}>{loading ? <Skeleton /> : project?.title}</h1>
            <p className={Style.Subtitle}>{loading ? <Skeleton /> : project?.regionTitle}</p>
          </div>

          {loading ? (
            <Skeleton variant="rectangular" height={200} />
          ) : (
            <div
              className={Style.Content}
              dangerouslySetInnerHTML={{ __html: project?.text ?? "" }}
            />
          )}

          <div className={Style.Tags}>
            {loading ? (
              <Skeleton width={"100%"} />
            ) : (
              project?.specializations &&
              project?.specializations.map((item) => <Tag key={item.id} label={item.title} />)
            )}
          </div>
          <Divider className={Style.Divider} />
          <div className={Style.Table}>
            {loading ? (
              <Skeleton variant="rectangular" height={200} />
            ) : (
              <>
                {project?.investmentSize && (
                  <>
                    <div className={Style.CellTitle}>Объём инвестиций</div>
                    <div className={Style.CellValue}>{project?.investmentSize}</div>
                  </>
                )}{" "}
                {project?.implementationPeriod && (
                  <>
                    <div className={Style.CellTitle}>Сроки реализации</div>
                    <div className={Style.CellValue}>{project?.implementationPeriod}</div>
                  </>
                )}
              </>
            )}
          </div>
          <Divider className={Style.Divider} />
          <Contacts
            website={project?.website}
            email={project?.email}
            address={project?.address}
            loading={loading}
          />
        </div>
      </div>
    </Container>
  );
};

export default ProjectPage;
