import axios from "axios";

export const defaultInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URL_SUFFIX}`,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    Accept: "application/json",
    // "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
  withCredentials: true,
});

export const authInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL_AUTH}/${process.env.REACT_APP_API_URL_AUTH_SUFFIX}`,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    Accept: "application/json",
    // "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
  withCredentials: false,
});

axios.interceptors.response.use(
  (response) => {
    if (response.data) {
      // return success
      if (response.status === 200 || response.status === 201) {
        return response;
      }
      // reject errors & warnings
      return Promise.reject(response);
    }

    // default fallback
    return Promise.reject(response);
  },
  (error) =>
    // if the server throws an error (404, 500 etc.)
    Promise.reject(error)
);
