import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Models from "@models";
import RequestStatus from "@utils/RequestStatus";
import API from "@api";
// import { Api } from "@mui/icons-material";

type T = Models.User;
type Response = Models.Response<T>;

interface IState extends Models.GenericState<Models.User | null> {
  jwt: string | null;
}

const initialState: IState = {
  data: null,
  jwt: null,
  status: RequestStatus.Waiting,
  error: "",
};

export const loadUser = createAsyncThunk("user/loadUser", async (_, { rejectWithValue }) => {
  try {
    const response: any = await API.Auth.currentUser();
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
  }
});

export const loadJWT = createAsyncThunk(
  "/user/loadJWT",
  async (data: Models.User | null, { rejectWithValue }) => {
    try {
      const response: any = await API.Auth.jwt({
        ...data,
        firstname: data?.firstName,
        lastname: data?.lastName,
      });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await API.User.fetchUser(id);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
    }
  }
);

// export const fetchUsers = createAsyncThunk("user/fetchUsers", async (_, { rejectWithValue }) => {
//   try {
//     const response = await API.User.fetchAll();
//     return response.data;
//   } catch (error) {
//     if (error instanceof Error) {
//       return rejectWithValue(error.message);
//     }
//   }
// });

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setJWT: (state, action: PayloadAction<string | null>) => {
      state.jwt = action.payload;
    },
  },
  extraReducers: {
    [loadUser.pending.type]: (state) => {
      state.status = RequestStatus.Pending;
    },
    [loadUser.fulfilled.type]: (state, action: PayloadAction<Response>) => {
      state.status = RequestStatus.Success;
      state.data = action.payload.data;
    },
    [loadUser.rejected.type]: (state, action: PayloadAction<string>) => {
      state.status = RequestStatus.Error;
      state.data = null;
      state.error = action.payload;
    },
    [loadJWT.fulfilled.type]: (state, action: PayloadAction<string>) => {
      state.jwt = action.payload;
    },
  },
});

export const { setJWT } = userSlice.actions;

export default userSlice.reducer;
