import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Models from "@models";
import RequestStatus from "@utils/RequestStatus";
import API from "@api";
import { DateString } from "@app/models/Types";
import dayjs from "@utils/dayjs";

interface BroadcastDay {
  date: DateString;
  broadcasts: Models.Broadcast[];
}

type T = BroadcastDay[];
type K = Models.Broadcast;
type ListResponse = Models.Response<T>;
type NearestResponse = Models.Response<K[]>;
type SubscribeResponse = Models.Response<K>;

interface IState {
  current: Models.GenericState<K | null>;
  list: Models.GenericState<T | null>;
  subscribe: Models.GenericState<K | null>;
  nearest: Models.GenericState<K[] | null>;
}

const initialState: IState = {
  current: {
    data: null,
    status: RequestStatus.Waiting,
    error: "",
  },
  list: {
    data: null,
    status: RequestStatus.Waiting,
    error: "",
  },
  nearest: {
    data: null,
    status: RequestStatus.Waiting,
    error: "",
  },
  subscribe: {
    data: null,
    status: RequestStatus.Waiting,
    error: "",
  },
};

export const fetchBroadcast = createAsyncThunk(
  "broadcasts/fetchBroadcast",
  async (id: number, { rejectWithValue }) => {
    try {
      const response: ListResponse = await API.Broadcasts.fetch(id);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchBroadcasts = createAsyncThunk(
  "broadcasts/fetchBroadcasts",
  async (_, { rejectWithValue }) => {
    try {
      const response: ListResponse = await API.Broadcasts.fetchAll();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchNearestBroadcasts = createAsyncThunk(
  "broadcasts/fetchNearest",
  async (_, { rejectWithValue }) => {
    try {
      const response: ListResponse = await API.Broadcasts.fetchNearest();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const broadcastSubscribe = createAsyncThunk(
  "broadcasts/broadcastSubscribe",
  async (id: number, { rejectWithValue }) => {
    try {
      const response: SubscribeResponse = await API.Broadcasts.subscribe(id);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
    }
  }
);

const broadcastsSlice = createSlice({
  name: "broadcasts",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchBroadcast.pending.type]: (state) => {
      state.current.status = RequestStatus.Pending;
    },
    [fetchBroadcast.fulfilled.type]: (state, action: PayloadAction<SubscribeResponse>) => {
      state.current.status = RequestStatus.Success;
      state.current.data = action.payload.data;
    },
    [fetchBroadcast.rejected.type]: (state, action: PayloadAction<string>) => {
      state.subscribe.status = RequestStatus.Error;
      state.subscribe.error = action.payload;
    },

    [fetchBroadcasts.pending.type]: (state) => {
      state.list.status = RequestStatus.Pending;
    },
    [fetchBroadcasts.fulfilled.type]: (state, action: PayloadAction<ListResponse>) => {
      state.list.status = RequestStatus.Success;
      state.list.data = action.payload.data;
    },
    [fetchBroadcasts.rejected.type]: (state, action: PayloadAction<string>) => {
      state.list.status = RequestStatus.Error;
      state.list.error = action.payload;
    },

    [fetchNearestBroadcasts.pending.type]: (state) => {
      state.nearest.status = RequestStatus.Pending;
    },
    [fetchNearestBroadcasts.fulfilled.type]: (state, action: PayloadAction<NearestResponse>) => {
      state.nearest.status = RequestStatus.Success;
      state.nearest.data = action.payload.data;
    },
    [fetchNearestBroadcasts.rejected.type]: (state, action: PayloadAction<string>) => {
      state.nearest.status = RequestStatus.Error;
      state.nearest.error = action.payload;
    },

    [broadcastSubscribe.pending.type]: (state) => {
      state.subscribe.status = RequestStatus.Pending;
    },
    [broadcastSubscribe.fulfilled.type]: (state, action: PayloadAction<SubscribeResponse>) => {
      state.subscribe.status = RequestStatus.Success;

      const responseBroadcast = action.payload.data;

      if (state.list.data) {
        for (const day of state.list.data) {
          if (day.date === dayjs(responseBroadcast.startAt).format("YYYY-MM-DD")) {
            for (const broadcast of day.broadcasts) {
              if (broadcast.id === responseBroadcast.id) {
                broadcast.subscriberList = responseBroadcast.subscriberList;
                break;
              }
            }
          }
        }
      }

      if (state.current.data?.id === responseBroadcast.id) {
        state.current.data.subscriberList = responseBroadcast.subscriberList;
      }

      if (state.nearest.data) {
        for (const broadcast of state.nearest.data) {
          if (broadcast.id === responseBroadcast.id) {
            broadcast.subscriberList = responseBroadcast.subscriberList;
            break;
          }
        }
      }

      state.subscribe.data = action.payload.data;
    },
    [broadcastSubscribe.rejected.type]: (state, action: PayloadAction<string>) => {
      state.subscribe.status = RequestStatus.Error;
      state.subscribe.error = action.payload;
    },
  },
});

export default broadcastsSlice.reducer;
