import React, { FC, useEffect } from "react";

const Referral: FC = () => {
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const referral = queryParams.get("reflink");
    if (typeof referral === "string" && referral.length) {
      localStorage.setItem("referral", referral);
    }
  }, []);

  return <></>;
};

export default Referral;
