import React, { FC } from "react";
import Style from "./PseudoModal.module.scss";

interface IProps {
  title?: string;
  titleSize?: "medium" | "small";
  className?: string;
  cols?: 1 | 2;
}

const PseudoModal: FC<IProps> = ({
  title,
  titleSize = "medium",
  className,
  cols = 1,
  children,
}) => (
  <div className={Style.Block}>
    <div className={Style.Header}>
      <p className={`${Style.Title} ${titleSize === "small" ? Style.TitleSmall : ""}`}>{title}</p>
    </div>

    <div className={`${Style.Body} ${cols === 2 ? Style.BodyCols2 : ""} ${className ?? ""}`}>
      {children}
    </div>
  </div>
);

export default PseudoModal;
