import { SnackbarKey } from "notistack";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Models from "@models";
// import RequestStatus from "@utils/RequestStatus";

type T = Models.Notification[];

const initialState: T = [];

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<any>) => {
      let isExist: boolean = false;
      state.forEach((notification) => {
        if (notification.options?.key === action.payload.key) {
          isExist = true;
        }
      });

      if (!isExist) {
        state.push({
          message: action.payload.message,
          options: {
            key: action.payload.key || new Date().getTime() + Math.random(),
            ...action.payload.options,
          },
        });
      }
    },
    closeNotification: (state, action: PayloadAction<SnackbarKey>) => {
      const index = state.findIndex((notification) => notification.options?.key === action.payload);
      if (index !== -1) {
        state[index].isClosed = true;
      }
    },
    closeAllNotifications: (state) => {
      state.forEach((notification) => {
        notification.isClosed = true;
      });
    },
    setShown: (state, action: PayloadAction<SnackbarKey>) => {
      const index = state.findIndex((notification) => notification.options?.key === action.payload);
      if (index !== -1) {
        state[index].isShown = true;
      }
    },
    removeNotification: (state, action: PayloadAction<SnackbarKey>) => {
      const index = state.findIndex((notification) => notification.options?.key === action.payload);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
  },
});

export const {
  addNotification,
  setShown,
  removeNotification,
  closeNotification,
  closeAllNotifications,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
