import { FormControl, InputLabel, FormHelperText, InputBase, InputBaseProps } from "@mui/material";
import React, { FC, FocusEvent } from "react";
import Style from "./Input.module.scss";

interface IProps {
  name: string;
  label: string;
  type?: React.HTMLInputTypeAttribute;
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  value: unknown;
  InputProps?: InputBaseProps;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

const Input: FC<IProps> = ({
  name,
  label,
  type = "text",
  helperText,
  error,
  disabled,
  required,
  fullWidth,
  value,
  InputProps,
  onChange,
  onBlur,
}) => {
  const blurHandler = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>): void => {
    if (onBlur) {
      onBlur(e);
    }
    if (!onBlur && onChange) {
      onChange(e);
    }
  };

  return (
    <FormControl
      className={Style.Wrapper}
      variant="filled"
      error={error}
      disabled={disabled}
      required={required}
      fullWidth={fullWidth}
    >
      <InputLabel
        className={Style.Label}
        classes={{ focused: Style.Focused, asterisk: Style.Asterisk, error: Style.Error }}
        htmlFor={name}
      >
        {label}
      </InputLabel>
      <InputBase
        id={name}
        type={type}
        name={name}
        value={value}
        classes={{
          root: Style.InputControl,
          input: Style.Input,
          focused: Style.Focused,
          error: Style.Error,
        }}
        onChange={onChange}
        onBlur={blurHandler}
        {...InputProps}
      />
      <FormHelperText classes={{ root: Style.Helper, error: Style.Error }}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default Input;
