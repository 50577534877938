import React from "react";
import { useSearchParams, NavigateOptions } from "react-router-dom";

const useQueryParams = <T>(
  key: string
): [T | undefined, (newQuery: T, options?: NavigateOptions) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramValue = searchParams.get(key);

  const value = React.useMemo(
    () => (paramValue ? JSON.parse(paramValue) : undefined),
    [paramValue]
  );

  const setValue = React.useCallback(
    (newValue: T, options?: NavigateOptions) => {
      const keys = Object.keys(newValue);

      if (keys.length) {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set(key, JSON.stringify(newValue));
        setSearchParams(newSearchParams, options);
      } else {
        setSearchParams({}, options);
      }
    },
    [key, searchParams, setSearchParams]
  );

  return [value, setValue];
};

export default useQueryParams;
