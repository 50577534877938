import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Models from "@models";
import RequestStatus from "@utils/RequestStatus";
import API from "@api";

type T = Models.Tag[];
type Response = Models.Response<T>;

const initialState: Models.GenericState<T | null> = {
  data: null,
  status: RequestStatus.Waiting,
  error: "",
};

export const fetchSpecializations = createAsyncThunk(
  "specializations/fetchSpecializations",
  async (_, { rejectWithValue }) => {
    try {
      const response: Response = await API.Tags.fetch("specialization");
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
    }
  }
);

const specializationsSlice = createSlice({
  name: "specializations",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSpecializations.pending.type]: (state) => {
      state.status = RequestStatus.Pending;
    },
    [fetchSpecializations.fulfilled.type]: (state, action: PayloadAction<Response>) => {
      state.status = RequestStatus.Success;
      state.data = action.payload.data;
    },
    [fetchSpecializations.rejected.type]: (state, action: PayloadAction<string>) => {
      state.status = RequestStatus.Error;
      state.error = action.payload;
    },
  },
});

export default specializationsSlice.reducer;
