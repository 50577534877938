import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useAppDispatch, useAppSelector } from "@hooks";
import { removeNotification, setShown } from "@reducers/UI/NotificationsSlice";

const useNotifier = (): any => {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector((store) => store.UI.Notifications || []);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    notifications.forEach((notification) => {
      const { message, options, isShown, isClosed } = notification;

      if (isClosed) {
        closeSnackbar(options?.key);
        return;
      }

      if (isShown) {
        return;
      }

      if (options?.key) {
        dispatch(setShown(options?.key));
      }

      enqueueSnackbar(message, {
        ...options,
        onClose: (event, reason, myKey) => {
          if (options?.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          dispatch(removeNotification(myKey));
        },
      });
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);
};

export default useNotifier;
