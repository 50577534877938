import { Skeleton as MUISkeleton, SkeletonProps } from "@mui/material";
import React, { FC } from "react";
import Style from "./Skeleton.module.scss";

interface IProps extends Partial<SkeletonProps> {}

const Skeleton: FC<IProps> = (props) => (
  <MUISkeleton classes={{ root: Style.Root, rectangular: Style.Rectangular }} {...props} />
);

export default Skeleton;
