import Models from "@models";
import ScrollToTopOnMount from "@utils/ScrollToTopOnMount";
import {
  Button,
  Checkbox,
  Container,
  Input,
  LabeledControl,
  PseudoModal,
  Select,
  SelectItem,
  ModalGroup,
  PhoneInput,
  Captcha,
} from "@components";
import React, { FC, useEffect, useState } from "react";
import Style from "./SignUpPage.module.scss";
import Validators from "@utils/Validators";
import { useAppDispatch, useAppSelector, useForm } from "@hooks";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { fetchCountries } from "@reducers/CountriesSlice";
import { addNotification } from "@reducers/UI/NotificationsSlice";
import Services from "@services";
import { openModal } from "@app/store/reducers/UI/ModalsSlice";
import privacyPolicy from "@assets/files/privacy_ru.pdf";

interface IForm {
  firstName: string;
  lastName: string;
  phone: string;
  country: string;
  city: string;
  company: string;
  position: string;
  scope: string;
  password: string;
  repeatedPassword: string;
  privacyPolicy: boolean;
  captcha: string | null | false;
}

const SignUpPage: FC = () => {
  const dispatch = useAppDispatch();
  const [isPasswordShow, setIsPasswordShow] = useState<boolean>(false);
  const [isPasswordsSame, setIsPasswordsSame] = useState<boolean | null>(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as { from: string };
  const email = searchParams.get("email");
  const Countries = useAppSelector((state) => state.Countries.data);
  const [scopeSelect, setScopeSelect] = useState("");
  const [referral, setReferral] = useState<string | undefined>(undefined);
  const scopes = [
    "Отельер",
    "Инвестор",
    "Управляющая компания",
    "Государственный служащий",
    "Туристическая компания",
    "Другое",
  ];

  const [fields, setValue, submitForm] = useForm<IForm>({
    firstName: {
      validators: [Validators.required()],
      value: "",
    },
    lastName: {
      validators: [Validators.required()],
      value: "",
    },
    phone: {
      validators: [Validators.required(), Validators.phone()],
      value: "7",
    },

    country: {
      validators: [Validators.required()],
      value: "",
    },
    city: {
      validators: [Validators.required()],
      value: "",
    },
    company: {
      validators: [Validators.required()],
      value: "",
    },
    position: {
      validators: [Validators.required()],
      value: "",
    },
    scope: {
      validators: [Validators.required()],
      value: "",
    },

    password: {
      validators: [Validators.required(), Validators.lengthCheck(8)],
      value: "",
    },
    repeatedPassword: {
      validators: [Validators.required()],
      value: "",
    },
    privacyPolicy: {
      validators: [Validators.required()],
      value: false,
    },
    captcha: {
      validators: [],
      value: null,
    },
  });

  useEffect(() => {
    const referral = localStorage.getItem("referral");
    if (referral) {
      console.log(referral);
      setReferral(referral);
    }
  }, []);

  useEffect(() => {
    if (!email) {
      navigate("/");
    }
  }, [email]);

  useEffect(() => {
    dispatch(fetchCountries()).then(() => {
      setValue("country", "144");
    });
  }, []);

  useEffect(() => {
    try {
      samePasswordCheck();
    } catch (error) {}
  }, [fields?.password.value, fields?.repeatedPassword.value]);

  const samePasswordCheck: () => void = () => {
    if (fields?.repeatedPassword.value) {
      if (fields?.password.value !== fields?.repeatedPassword.value) {
        setIsPasswordsSame(false);
        throw new Error("Введённые пароли отличаются");
      } else {
        setIsPasswordsSame(true);
      }
    }
  };

  const changeHandler: Models.InputChangeHandler = (e) => {
    setValue(e.target.name as keyof IForm, e.target.value);
  };

  const scopeSelectChangeHandler: Models.SelectChangeHandler = (e) => {
    setScopeSelect(e.target.value);
    if (e.target.value !== scopes[scopes.length - 1]) {
      setValue("scope", e.target.value);
    } else {
      setValue("scope", "");
    }
  };

  const selectChangeHandler: Models.SelectChangeHandler = (e) => {
    setValue(e.target.name as keyof IForm, e.target.value);
  };

  const formSubmitHandler: Models.SubmitHandler = (e) => {
    e.preventDefault();
    try {
      submitForm();
      samePasswordCheck();
      Services.Auth.SignUp(
        {
          email: email ? email : "",
          profile: {
            firstName: fields?.firstName.value,
            lastName: fields?.lastName.value,
            phone: fields?.phone.value,
            country: fields?.country.value,
            city: fields?.city.value,
            workName: fields?.company.value,
            position: fields?.position.value,
            scopeName: fields?.scope.value,
          },
          plainPassword: {
            first: fields?.password.value,
            second: fields?.repeatedPassword.value,
          },
          agreeTerms: fields?.privacyPolicy.value,
        },
        fields?.captcha.value,
        referral
      ).then(() => {
        localStorage.removeItem("referral");
        navigate(locationState?.from ?? "/");
        Services.Auth.LoadUser();
      });
    } catch (error: any) {
      dispatch(addNotification({ message: error.message, options: { variant: "error" } }));
    }
  };

  const onCaptchaChange = (value: string | null | false) => {
    setValue("captcha", value);
  };

  const backBtnClickHandler = (): void => {
    navigate(locationState?.from ?? "/");
    dispatch(openModal({ name: "auth" }));
  };

  return (
    <Container type="pseudomodal">
      <ScrollToTopOnMount />
      <form className={Style.Form} noValidate onSubmit={formSubmitHandler}>
        <PseudoModal title="Регистрация">
          <ModalGroup>
            <div className={Style.Email}>
              <p className={Style.EmailTitle}>Email:</p>
              <p className={Style.EmailContent}>{email}</p>
            </div>
          </ModalGroup>
          <ModalGroup>
            <Input
              type="text"
              label="Имя"
              name="firstName"
              required
              error={fields?.firstName.isValid === false}
              helperText={fields?.firstName.error}
              fullWidth
              value={fields?.firstName.value}
              onChange={changeHandler}
            />
            <Input
              type="text"
              label="Фамилия"
              name="lastName"
              required
              error={fields?.lastName.isValid === false}
              helperText={fields?.lastName.error}
              fullWidth
              value={fields?.lastName.value}
              onChange={changeHandler}
            />
          </ModalGroup>
        </PseudoModal>
        <PseudoModal title="Ваши данные" titleSize="small">
          <ModalGroup>
            <PhoneInput
              value={fields?.phone.value}
              error={fields?.phone.isValid === false}
              helperText={fields?.phone.error}
              onChange={(value) => {
                setValue("phone", `+${value}`);
              }}
              required
              country="ru"
            />

            <Select
              name="country"
              label="Ваша страна"
              required
              error={fields?.country.isValid === false}
              defaultValue=""
              helperText={fields?.country.error}
              value={fields?.country.value}
              onChange={selectChangeHandler}
            >
              {Countries?.map((country) => (
                <SelectItem key={country.id} value={country.id}>
                  {country.name}
                </SelectItem>
              ))}
            </Select>
            <Input
              type="text"
              label="Город"
              name="city"
              required
              error={fields?.city.isValid === false}
              helperText={fields?.city.error}
              fullWidth
              value={fields?.city.value}
              onChange={changeHandler}
            />
          </ModalGroup>
        </PseudoModal>
        <PseudoModal title="Ваша компания" titleSize="small">
          <ModalGroup>
            <Input
              type="text"
              label="Ваша компания"
              name="company"
              required
              error={fields?.company.isValid === false}
              helperText={fields?.company.error}
              fullWidth
              value={fields?.company.value}
              onChange={changeHandler}
            />
            <Input
              type="text"
              label="Ваша должность"
              name="position"
              required
              error={fields?.position.isValid === false}
              helperText={fields?.position.error}
              fullWidth
              value={fields?.position.value}
              onChange={changeHandler}
            />
          </ModalGroup>

          <ModalGroup>
            <p className={Style.FieldDesc}>Укажите ваше профессиональную сферу деятельности</p>
            <Select
              name="specialization"
              label="Сфера деятельности"
              required
              error={fields?.scope.isValid === false && !scopeSelect}
              helperText={fields?.scope.error}
              value={scopeSelect}
              onChange={scopeSelectChangeHandler}
            >
              {scopes?.map((scope) => (
                <SelectItem key={scope} value={scope}>
                  {scope}
                </SelectItem>
              ))}
            </Select>
            {scopeSelect === scopes[scopes.length - 1] && (
              <Input
                type="text"
                label="Напишите вашу сферу деятельности"
                name="scope"
                required
                error={fields?.scope.isValid === false}
                helperText={fields?.scope.error}
                fullWidth
                value={fields?.scope.value}
                onChange={changeHandler}
              />
            )}
          </ModalGroup>
        </PseudoModal>
        <PseudoModal title="Пароль и подтверждение" titleSize="small">
          <ModalGroup>
            <Input
              type={isPasswordShow ? "text" : "password"}
              label="Пароль"
              name="password"
              required
              error={fields?.password.isValid === false}
              helperText={fields?.password.error}
              fullWidth
              value={fields?.password.value}
              onChange={changeHandler}
            />
            <Input
              type={isPasswordShow ? "text" : "password"}
              label="Пароль"
              name="repeatedPassword"
              required
              error={isPasswordsSame === false || fields?.repeatedPassword.isValid === false}
              helperText={
                isPasswordsSame === false ? "Пароли отличаются" : fields?.repeatedPassword.error
              }
              fullWidth
              value={fields?.repeatedPassword.value}
              onChange={changeHandler}
            />
            <LabeledControl
              control={<Checkbox />}
              label={"Показать пароль"}
              name={"passwordShow"}
              className={Style.Checkbox}
              onChange={() => {
                setIsPasswordShow(!isPasswordShow);
              }}
            />
          </ModalGroup>

          <Captcha value={fields?.captcha.value} onChange={onCaptchaChange} />
          <LabeledControl
            control={
              <Checkbox
                onChange={(e, checked) => {
                  setValue("privacyPolicy", checked);
                }}
              />
            }
            label={
              <>
                Соглашаюсь с{" "}
                <Link to={privacyPolicy} target="_blank">
                  политикой обработки данных
                </Link>{" "}
                и пользовательским соглашением
              </>
            }
            name={"privacyPolicy"}
            className={Style.PrivacyCheckbox}
            helperText={fields?.privacyPolicy.error}
            error={fields?.privacyPolicy.isValid === false}
          />
          <ModalGroup cols={2}>
            <Button view="pale" onClick={backBtnClickHandler}>
              Назад
            </Button>
            <Button type="submit">Зарегистрировать</Button>
          </ModalGroup>
        </PseudoModal>
      </form>
    </Container>
  );
};

export default SignUpPage;
