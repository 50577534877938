import { FC } from "react";
import { Footer, HashScroll, Header, Referral } from "@components";
import Style from "./Layout.module.scss";
import { Outlet } from "react-router-dom";

interface IProps {
  type?: "index" | "default" | "region";
}

const Layout: FC<IProps> = ({ type = "default" }) => {
  const mainClasses: string[] = [Style.Main];

  switch (type) {
    case "index":
      mainClasses.push(Style.Index);
      break;
    case "region":
      mainClasses.push(Style.Region);
      break;

    default:
      break;
  }

  return (
    <section className={`${Style.Layout} `}>
      <Referral />
      <HashScroll />
      <Header />
      <main className={mainClasses.join(" ")}>
        <Outlet />
      </main>
      <Footer />
    </section>
  );
};
export default Layout;
