import { useAppDispatch, useAppSelector } from "@hooks";
import { Block, Container, Divider, Project } from "@components";
import React, { FC, useEffect, useState } from "react";
import Style from "./CatalogPage.module.scss";
import Filter from "./components/Filter";
import Header from "./components/Header";
import { fetchRegions } from "@reducers/RegionsSlice";
import { fetchSpecializations } from "@reducers/Tags/SpecializationsSlice";
import ScrollToTopOnMount from "@utils/ScrollToTopOnMount";

const CatalogPage: FC = () => {
  const [isFilterShow, setisFilterShow] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const catalog = useAppSelector((state) => state.Projects.data);

  useEffect(() => {
    dispatch(fetchRegions());
    dispatch(fetchSpecializations());
  }, []);

  return (
    <Container className={Style.Root}>
      <ScrollToTopOnMount />
      <Header isFilterShow={isFilterShow} setIsFilterShow={setisFilterShow} />
      <div className={Style.Wrapper}>
        <Filter isFilterShow={isFilterShow} />
        <Block className={Style.Main}>
          {catalog &&
            Object.keys(catalog).map((key, index) => (
              <>
                <div key={`${key}-${index}`} className={Style.Region}>
                  <h2 className={Style.RegionTitle}>{key}</h2>
                  <div className={Style.Projects}>
                    {catalog[key]?.map((project) => (
                      <Project key={project.id} data={project} />
                    ))}
                  </div>
                </div>
                <Divider className={Style.Divider} />
              </>
            ))}
        </Block>
      </div>
    </Container>
  );
};
export default CatalogPage;
