import React, { FC } from "react";
import { Button as MUIButton } from "@mui/material";
import Style from "./IconButton.module.scss";
import { Link } from "react-router-dom";

interface IProps {
  className?: string;
  icon: React.ReactNode;
  to?: string;
}

const ScheduleButton: FC<IProps> = ({ className, icon, children, to }) => {
  const classes = [Style.Button, className];

  return (
    <MUIButton
      classes={{
        root: Style.Root,
        sizeLarge: Style.Large,
        sizeMedium: Style.Medium,
        disabled: Style.Disabled,
      }}
      className={classes.join(" ")}
      component={to ? Link : "button"}
      to={to}
    >
      <span className={Style.Text}>{children}</span>
      <span className={Style.Icon}>
        <i className={`icon-${icon}`} />
      </span>
    </MUIButton>
  );
};

export default ScheduleButton;
