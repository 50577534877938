import React, { FC } from "react";
import Style from "./Time.module.scss";

interface IProps {
  className?: string;
  datetime?: string;
  timezone?: string;
}

const Time: FC<IProps> = ({ className, datetime, children, timezone = "МСК" }) => (
  <time dateTime={datetime} className={`${Style.Time} ${className || ""}`}>
    {children}
    <sup>{timezone}</sup>
  </time>
);

export default Time;
