import ScrollToTopOnMount from "@utils/ScrollToTopOnMount";
import { Container } from "@components";
import { FC } from "react";
import About from "./components/About";
import Catalog from "./components/Catalog";
import Dynamic from "./components/Dynamic";
import Header from "./components/Header";
// import Regions from "./components/Regions";
import Schedule from "./components/Schedule";
import Style from "./HomePage.module.scss";
import PartnersBottom from "./components/PartnersBottom";

const HomePage: FC = () => (
  <div className={Style.Root}>
    <ScrollToTopOnMount />
    <Header />
    <Container type="index" className={Style.Container}>
      <About />
      <Dynamic />
      <Schedule />
      {/* <Regions /> */}
      <Catalog />
      <PartnersBottom />
    </Container>
  </div>
);

export default HomePage;
