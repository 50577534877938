import React, { FC } from "react";
import { Link } from "react-router-dom";
import Style from "./Broadcast.module.scss";
import { Divider, IconButton, ScheduleButton, Time } from "@components";
import Slider from "./Slider";
import Models from "@models";
import dayjs from "dayjs";
import Routes from "@utils/Routes";

interface IProps {
  data: Models.Broadcast;
}

const Broadcast: FC<IProps> = ({ data }) => (
  // const [isMoreShown, setIsMoreShow] = useState<boolean>(false);

  <div className={Style.Root}>
    <div className={Style.Main}>
      <Time>{dayjs(data.startAt).tz("Europe/Moscow").format("HH:mm")}</Time>
      <div className={Style.Content}>
        <Link to={`${Routes.Broadcast.path}/${data.id}`} className={Style.Title}>
          {data.title}
        </Link>
        {/* <p className={Style.Title}>{data.title}</p> */}
        <p className={Style.Desc} dangerouslySetInnerHTML={{ __html: data.innerDescription }} />

        {/* <MUIButton
            className={`${Style.MoreBtn} ${Style.Custom}`}
            onClick={() => {
              setIsMoreShow(!isMoreShown);
            }}
          >
            Подробнее
          </MUIButton>
          <Collapse in={isMoreShown}>
            <div
              className={Style.More}
              dangerouslySetInnerHTML={{ __html: data.innerDescription }}
            />
          </Collapse> */}
      </div>
    </div>
    <div className={Style.BtnWrapper}>
      {dayjs() > dayjs(data?.startAt).clone().subtract(30, "minutes") ? (
        <IconButton icon="filled-play" to={`${Routes.Broadcast.path}/${data.id}`}>
          Смотреть
        </IconButton>
      ) : (
        <ScheduleButton
          className={Style.ScheduleBtn}
          id={data.id}
          subscribersList={data.subscriberList}
        />
      )}
      <p className={Style.Participants}>Записалось {data.subscriberList.length} участников</p>
    </div>
    {data.speakerList.length > 0 && (
      <>
        <Divider className={Style.Divider} />
        <Slider experts={data.speakerList} />
      </>
    )}
  </div>
);
export default Broadcast;
