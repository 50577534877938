import React, { FC } from "react";
import { Button } from "@components";
import Style from "./Project.module.scss";
import Models from "@models";
import Routes from "@utils/Routes";

interface IProps {
  data: Models.Project;
}

const Project: FC<IProps> = ({ data }) => (
  <div className={Style.Item}>
    <div className={Style.Img}>
      <img src={data.photo} alt="" />
    </div>
    <div className={Style.Content}>
      <h4 className={Style.Title}>{data.title}</h4>
      <div className={Style.PriceBlock}>
        <p className={Style.Price}>{data.investmentSize}</p>
        <Button view="outlined" to={`${Routes.Project.path}/${data.id}`}>
          Подробнее
        </Button>
      </div>
      <p className={Style.Description}>{data.description}</p>
    </div>
  </div>
);
export default Project;
