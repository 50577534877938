import store from "@store/index";
import API from "@api";
import Models from "@models";
import {
  addNotification,
  closeNotification,
  removeNotification,
} from "@reducers/UI/NotificationsSlice";

const SignUp = async (
  data: Models.Auth.SignUp,
  captcha: string | null | false,
  referral: string | undefined
): Promise<any> => {
  store.dispatch(
    addNotification({ key: "regWait", message: "Регистрация...", options: { persist: true } })
  );
  const response = await API.Auth.signUp(data, captcha, referral)
    .then(() => {
      store.dispatch(closeNotification("regWait"));
      store.dispatch(
        addNotification({
          message: "Регистрация успешно завершена!",
          options: { variant: "success" },
        })
      );
    })
    .catch((error) => {
      store.dispatch(removeNotification("regWait"));
      store.dispatch(
        addNotification({ message: error.response.data.error, options: { variant: "error" } })
      );
    });
  return response;
};

export default SignUp;
