import store from "@store/index";
import API from "@api";
import {
  addNotification,
  closeNotification,
  removeNotification,
} from "@reducers/UI/NotificationsSlice";

const PasswordReset = (
  data: {
    plainPassword: { newPassword: string; passwordConfirm: string };
  },
  token: string
): Promise<any> => {
  store.dispatch(
    addNotification({ key: "changing", message: "Меняем пароль...", options: { persist: true } })
  );
  const response: any = API.Auth.newPassword(data, token)
    .then(() => {
      store.dispatch(closeNotification("changing"));
      store.dispatch(
        addNotification({
          message: "Пароль изменён!",
          options: { variant: "success" },
        })
      );
    })
    .catch((error) => {
      store.dispatch(removeNotification("changing"));
      store.dispatch(
        addNotification({ message: error.response.data.error, options: { variant: "error" } })
      );
      return Promise.reject(response);
    });
  return Promise.resolve(response);
};

export default PasswordReset;
