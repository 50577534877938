import { Skeleton } from "@mui/material";
import React, { FC } from "react";
import Style from "./Contacts.module.scss";

interface IProps {
  website?: string;
  email?: string;
  address?: string;
  loading?: boolean;
}

const Contacts: FC<IProps> = ({ website, email, address, loading = false }) => (
  <>
    {loading ? (
      <Skeleton variant="rectangular" height={200} />
    ) : (
      <div className={Style.Contacts}>
        {website && (
          <a href={website} className={Style.Website}>
            {website}
          </a>
        )}

        {email && (
          <a href={`mailto:${email}`} className={Style.Mail}>
            {email}
          </a>
        )}

        {address && <p className={Style.Address}>{address}</p>}
      </div>
    )}
  </>
);

export default Contacts;
