import Models from "@models";
import { authInstance, defaultInstance } from "./instances";

export const currentUser: () => Promise<any> = async () => await defaultInstance.get(`/user`);

export const checkEmail: (email: string) => Promise<any> = async (email) =>
  await defaultInstance.post(`/auth/email/check`, { login: email });

export const signIn = async (email: string, password: string): Promise<any> =>
  await defaultInstance.post(`/auth/signin`, { login: email, password });

export const signOut = async (): Promise<any> =>
  await defaultInstance.post<Promise<any>>(`/auth/signout`);

export const signUp = async (
  data: Models.Auth.SignUp,
  captcha: string | null | false,
  referralHash: string | undefined
): Promise<any> =>
  await defaultInstance.post(`/auth/registration`, { form: data, captcha, referralHash });

// ! TODO: убрать any
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const jwt = async (data: any): Promise<any> =>
  await authInstance.post<Promise<any>>(`/auth/signin`, data);

export const passwordReset = async (form: { email: string }): Promise<any> =>
  await defaultInstance.post<Promise<any>>(`/auth/password/reset/request`, { form });

export const newPassword = async (
  form: {
    plainPassword: { newPassword: string; passwordConfirm: string };
  },
  token: string
): Promise<any> =>
  await defaultInstance.post<Promise<any>>(`/auth/password/reset/${token}`, { form });
