import React, { FC } from "react";

interface IProps {
  code?: string;
}

const CaptchaFigure: FC<IProps> = ({ code }) => {
  switch (code) {
    case "0BJc": {
      return (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="7" y="7" width="34" height="34" rx="5" stroke="#222222" strokeWidth="2" />
        </svg>
      );
    }
    case "XDYg": {
      return (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="24" cy="24" r="19" stroke="#222222" strokeWidth="2" />
        </svg>
      );
    }
    case "kstA": {
      return (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.4275 8.23112C22.5927 6.2891 25.4073 6.2891 26.5725 8.23112L43.5077 36.4565C44.7075 38.4561 43.2671 41 40.9352 41H7.06476C4.73288 41 3.29254 38.4561 4.49228 36.4565L21.4275 8.23112Z"
            stroke="#222222"
            strokeWidth="2"
          />
        </svg>
      );
    }
    case "vDzr": {
      return (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            // eslint-disable-next-line max-len
            d="M21.3098 4.71051C22.4102 2.48077 25.5898 2.48077 26.6902 4.71051L30.1077 11.6351C30.836 13.1108 32.2438 14.1336 33.8724 14.3703L41.5141 15.4807C43.9747 15.8382 44.9573 18.8622 43.1767 20.5978L37.6471 25.9878C36.4687 27.1365 35.931 28.7915 36.2091 30.4134L37.5145 38.0243C37.9348 40.475 35.3625 42.3439 33.1617 41.1868L26.3267 37.5934C24.8701 36.8277 23.1299 36.8277 21.6733 37.5934L14.8383 41.1868C12.6375 42.3439 10.0652 40.475 10.4855 38.0243L11.7909 30.4134C12.069 28.7915 11.5313 27.1365 10.3529 25.9878L4.82328 20.5978C3.04273 18.8622 4.02526 15.8382 6.48592 15.4807L14.1276 14.3703C15.7562 14.1336 17.164 13.1108 17.8923 11.6351L21.3098 4.71051Z"
            stroke="#222222"
            strokeWidth="2"
          />
        </svg>
      );
    }
    case "x3z9": {
      return (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            // eslint-disable-next-line max-len
            d="M23.3905 9.35541L24 9.82402L24.6095 9.35541C29.0969 5.90538 35.5394 6.24514 39.6434 10.3757C44.1189 14.8802 44.1189 22.1862 39.6434 26.6907L27.6934 38.7181C26.4676 39.9518 26.0136 40.3911 25.5629 40.6223C25.0645 40.8779 24.5202 41.0037 23.9765 40.9999H23.9765C23.4485 40.9963 22.9212 40.8706 22.4371 40.6223C21.9864 40.3911 21.5324 39.9518 20.3066 38.7181L8.35664 26.6907C3.88112 22.1862 3.88112 14.8802 8.35664 10.3757C12.4606 6.24514 18.9031 5.90538 23.3905 9.35541Z"
            stroke="#222222"
            strokeWidth="2"
          />
        </svg>
      );
    }
    default:
      return <></>;
  }
};

export default CaptchaFigure;
