import { FC } from "react";
import Style from "./Divider.module.scss";

interface IProps {
  type?: "vertical" | "horizontal";
  color?: "grey" | "darkgrey";
  className?: string;
}

const Divider: FC<IProps> = ({ type = "horizontal", color = "grey", className }) => {
  const classes: string[] = [Style.Divider];

  if (className) {
    classes.push(className);
  }

  switch (type) {
    case "horizontal":
      classes.push(Style.Horizontal);
      break;

    case "vertical":
      classes.push(Style.Vertical);
      break;

    default:
      break;
  }

  switch (color) {
    case "grey":
      classes.push(Style.Grey);
      break;

    case "darkgrey":
      classes.push(Style.Darkgrey);
      break;

    default:
      break;
  }

  return <hr className={classes.join(" ")} />;
};

export default Divider;
