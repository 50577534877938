import { Block, Divider } from "@components";
import React, { FC } from "react";
import Style from "./PartnersBottom.module.scss";
import partner1 from "@img/partners/partner-b-1.svg";
import partner2 from "@img/partners/partner-b-2.svg";
import partner3 from "@img/partners/partner-b-3.svg";
import partner4 from "@img/partners/partner-b-4.svg";
import partner5 from "@img/partners/partner-b-5.svg";
import partner6 from "@img/partners/partner-b-6.svg";
import partner7 from "@img/partners/partner-b-7.svg";
import partner8 from "@img/partners/partner-b-8.svg";
import partner9 from "@img/partners/partner-b-9.svg";

const PartnersBottom: FC = () => {
  const partners = [
    partner1,
    partner2,
    partner3,
    partner4,
    partner5,
    partner6,
    partner7,
    partner8,
    partner9,
  ];

  return (
    <Block className={Style.Root}>
      <h2 className={Style.Title}>Информационные партнеры форума</h2>
      <Divider />
      <ul className={Style.List}>
        {partners.map((partner, index) => (
          <li className={Style.Item} key={index}>
            <img src={partner} />
          </li>
        ))}
      </ul>
    </Block>
  );
};

export default PartnersBottom;
