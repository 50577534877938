import React, { FC, useEffect, useState } from "react";

import Preloader from "./components/preloader";

import Figure from "./components/figures";

import Style from "./Captcha.module.scss";
import API from "@api";
import { Button } from "@components";

interface IProps {
  value?: string | null | false;
  onChange: (value: string | null) => void;
}

const CaptchaComponent: FC<IProps> = ({ value, onChange }) => {
  const [captchaList, setCaptchaList] = useState([]);
  const [captchaQuestion, setCaptchaQuestion] = useState();
  const [valid, setValid] = useState<boolean>(true);

  const [loading, setLoading] = useState<boolean>(false);

  const loadData = async () => {
    setLoading(true);
    API.Captcha.request()
      .then((response) => {
        setCaptchaList(response.data.data.itemList);
        setCaptchaQuestion(response.data.data.message);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setValid(Boolean(typeof value === "string" && value.length));
  }, [value]);

  return (
    <>
      <div className={[Style.Captcha, valid ? null : Style.Error].join(" ")}>
        <div className={Style.CaptchaMessage}>
          <div className={Style.CaptchaMessageHint}>Captcha</div>
          {value === false ? (
            <span className={Style.TextRed}>Выбран неправильный символ</span>
          ) : (
            captchaQuestion
          )}
        </div>
        {value === false ? (
          <div className={Style.CaptchaButton}>
            <Button
              onClick={(e: any) => {
                e.preventDefault();
                onChange(null);
                loadData();
              }}
            >
              Перезагрузить капчу
            </Button>
          </div>
        ) : (
          <div className={Style.CaptchaSelect}>
            {captchaList?.map((item: any, index: number) => (
              <label key={index}>
                <input
                  type="radio"
                  value={item}
                  checked={item === value}
                  name="captcha"
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                />
                <Figure code={item} />
                123
              </label>
            ))}
          </div>
        )}
        <input
          type="text"
          name="captcha-validator"
          className={Style.HiddenInput}
          value={value || undefined}
          onChange={(e) => e.preventDefault()}
          required
        />
        {loading ? <Preloader /> : <></>}
      </div>
    </>
  );
};

export default CaptchaComponent;
