import { FC } from "react";
import Style from "./Container.module.scss";

interface IProps {
  className?: string;
  type?: "standard" | "pseudomodal" | "project" | "region" | "index";
}

const Container: FC<IProps> = ({ children, className, type = "standard" }) => {
  const classes: string[] = [Style.Container];

  if (className) {
    classes.push(className);
  }

  switch (type) {
    case "pseudomodal":
      classes.push(Style.PseudoModal);
      break;

    case "project":
      classes.push(Style.Project);
      break;

    case "region":
      classes.push(Style.Region);
      break;

    case "index":
      classes.push(Style.Index);
      break;

    default:
      break;
  }

  return <div className={classes.join(" ")}>{children}</div>;
};

export default Container;
