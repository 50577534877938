import { useAppSelector } from "@hooks";
import { Block, Divider, Logo, Skeleton } from "@components";
import React, { FC } from "react";
import Style from "./Partners.module.scss";
import RequestStatus from "@utils/RequestStatus";

interface IProps {
  className?: string;
}

const Partners: FC<IProps> = ({ className }) => {
  const BroadcastStatus = useAppSelector((state) => state.Broadcasts.current.status);
  const isLoading = BroadcastStatus !== RequestStatus.Success;

  const Organizers = useAppSelector(
    (state) => state.Broadcasts.current.data?.broadcastOrganizerLogoList
  );
  const Partners = useAppSelector(
    (state) => state.Broadcasts.current.data?.broadcastPartnerLogoList
  );

  if (isLoading) {
    return <Skeleton height={350} variant="rectangular" className={className} />;
  }

  if (Organizers?.length || Partners?.length) {
    return (
      <Block type="broadcast" className={`${Style.Root} ${className || ""}`}>
        {Organizers?.length && (
          <div className={Style.Group}>
            <p className={Style.GroupTitle}>Организаторы:</p>
            <ul className={Style.GroupList}>
              {Organizers?.map((item, index: number) => (
                <li key={`organizer-${index}`} className={Style.GroupItem}>
                  <Logo src={item.image} />
                </li>
              ))}
            </ul>
          </div>
        )}
        {Organizers?.length && Partners?.length && <Divider />}
        {Partners?.length && (
          <div className={Style.Group}>
            <p className={Style.GroupTitle}>Партнеры:</p>
            <ul className={Style.GroupList}>
              {Partners?.map((item, index: number) => (
                <li key={`organizer-${index}`} className={Style.GroupItem}>
                  <Logo src={item.image} />
                </li>
              ))}
            </ul>
          </div>
        )}
      </Block>
    );
  }

  return <></>;
};

export default Partners;
