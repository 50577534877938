import React, { FC, useEffect, useState } from "react";
import { Button as MUIButton } from "@mui/material";
import Style from "./ScheduleButton.module.scss";
import { useAppDispatch, useAppSelector } from "@hooks";
import { openModal } from "@reducers/UI/ModalsSlice";
import Models from "@models";
import RequestStatus from "@utils/RequestStatus";
import Services from "@services";

const enum UserStatus {
  Waiting,
  Subscribed,
  Unsubscribed,
}

interface IProps {
  id: number;
  className?: string;
  subscribersList: Models.User[];
}

const ScheduleButton: FC<IProps> = ({ id, className, subscribersList }) => {
  const dispatch = useAppDispatch();
  const User = useAppSelector((state) => state.User.data);
  const SubscribeStatus = useAppSelector((state) => state.Broadcasts.subscribe.status);
  const [broadcastStatus, setBroadcastStatus] = useState<UserStatus>(UserStatus.Waiting);
  const classes = [Style.Button, className];

  let text;
  let icon;

  switch (broadcastStatus) {
    case UserStatus.Waiting:
      text = "Записаться";
      icon = "plus";
      break;

    case UserStatus.Subscribed:
      text = "Записан";
      icon = "check";
      classes.push(Style.Subscribed);
      break;

    case UserStatus.Unsubscribed:
      text = "Join";
      icon = "plus";
      break;

    default:
      break;
  }

  useEffect(() => {
    let isSubscribed: boolean = false;

    for (const subscriber of subscribersList) {
      if (User?.id === subscriber.id) {
        isSubscribed = true;
        break;
      }
    }
    setBroadcastStatus(isSubscribed ? UserStatus.Subscribed : UserStatus.Waiting);
  }, [subscribersList, User]);

  const clickHandler = (): void => {
    if (User) {
      Services.BroadcastSubscribe(id, broadcastStatus === UserStatus.Subscribed ? true : false);
    } else {
      dispatch(openModal({ name: "auth" }));
    }
  };

  return (
    <MUIButton
      classes={{
        root: Style.Root,
        sizeLarge: Style.Large,
        sizeMedium: Style.Medium,
        disabled: Style.Disabled,
      }}
      className={classes.join(" ")}
      onClick={clickHandler}
      disabled={SubscribeStatus === RequestStatus.Pending}
    >
      <span className={Style.Text}>{text}</span>
      <span className={Style.Icon}>
        <i className={`icon-${icon}`} />
      </span>
    </MUIButton>
  );
};

export default ScheduleButton;
