import { setJWT } from "@reducers/UserSlice";
import store from "@store/index";
import {
  addNotification,
  closeNotification,
  removeNotification,
} from "@reducers/UI/NotificationsSlice";
import API from "@api";
import Services from "@services";

const SignOut = async (): Promise<any> => {
  store.dispatch(
    addNotification({
      key: "signOut",
      message: "Выход из учётной записи...",
      options: { persist: true },
    })
  );

  await API.Auth.signOut()
    .then(() => {
      store.dispatch(closeNotification("signOut"));
      Services.Auth.LoadUser();
      store.dispatch(setJWT(null));
      store.dispatch(
        addNotification({
          message: "Выход успешно выполнен",
          options: { variant: "success" },
        })
      );
    })
    .catch((error) => {
      store.dispatch(removeNotification("signOut"));
      store.dispatch(
        addNotification({ message: error.response.data.error, options: { variant: "error" } })
      );
    });
};

export default SignOut;
