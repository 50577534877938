import React, { FC } from "react";
import img from "@img/index-catalog.jpg";
import { Button } from "@components";
import Style from "./Catalog.module.scss";
// import Routes from "@utils/Routes";
import { useAppDispatch } from "@hooks";
import { addNotification } from "@app/store/reducers/UI/NotificationsSlice";

const Catalog: FC = () => {
  const dispatch = useAppDispatch();

  return (
    <section className={Style.Root} id="catalog">
      <div className={Style.Img}>
        <img src={img} />
      </div>
      <div className={Style.Body}>
        <div className={Style.Content}>
          <h2 className={Style.Title}>Каталог инвестпроектов</h2>
          <p className={Style.Desc}>
            Найдите перспективные проекты для инвестиций в туристическую отрасль
          </p>
        </div>
        {/* <Button className={Style.Btn} to={Routes.Catalog.pathname} size="large">
              Перейти в каталог
            </Button> */}
        <Button
          className={Style.Btn}
          size="large"
          view="outlined"
          onClick={() => {
            dispatch(
              addNotification({
                message: "Каталог будет доступен немного позже!",
                options: { variant: "info" },
              })
            );
          }}
        >
          Перейти в каталог
        </Button>
      </div>
    </section>
  );
};

export default Catalog;
