import React, { FC, useEffect, useState } from "react";
import Chat from "./chat_fc";
import ReactPlayer from "react-player";

import Style from "./Player.module.scss";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "@hooks";
import { Button, ScheduleButton } from "@components";
import { openModal } from "@reducers/UI/ModalsSlice";
import Models from "@models";

interface IProps {
  className?: string;
  currentBroadcastStatus: BroadcastStatus;
}

enum BroadcastStatus {
  Past,
  Live,
  Future,
}

const BroadcastPlayer: FC<IProps> = ({ className, currentBroadcastStatus }) => {
  const User = useAppSelector((state) => state.User.data);
  const jwt = useSelector((state: any) => state.User.jwt);
  const dispatch = useAppDispatch();

  const [isVideoPlayed, setIsVideoPlayed] = useState<boolean>(true);

  const Broadcast: Models.Broadcast = useSelector((state: any) => state.Broadcasts.current.data);
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);

  useEffect(() => {
    if (Broadcast?.subscriberList.length) {
      for (const subscriber of Broadcast?.subscriberList) {
        if (User?.id === subscriber.id) {
          setIsSubscribed(true);
          break;
        } else {
          setIsSubscribed(false);
        }
      }
    }
  }, [Broadcast, User]);

  // TODO: Избавиться от сессии. Хранить глобально в виде JWT токена. КОСТЫЛЬ.

  return currentBroadcastStatus !== BroadcastStatus.Future && Broadcast.youtubeUrlId ? (
    <div className={`${Style.PlayerWrapper} ${className ?? ""}`}>
      <div className={`${Style.Player} ${!User || !isSubscribed ? Style.PlayerBlured : ""}`}>
        <ReactPlayer
          url={`https://www.youtube.com/watch?v=${Broadcast?.youtubeUrlId}`}
          width="100%"
          height="100%"
          playing={isVideoPlayed}
          // autoplay
          muted
          controls
          config={{
            youtube: {
              playerVars: { showinfo: 0, modestbranding: 1, iv_load_policy: 3, rel: 0 },
            },
          }}
        />

        {!User ? (
          <>
            <div className={Style.LoginWrapper}>
              <div className={Style.Login}>
                <div className={Style.LoginContent}>
                  <p className={Style.LoginTitle}>Войти и смотреть</p>
                  <p className={Style.LoginDescription}>
                    Чтобы смотреть трансляцию и общаться в чате, необходимо войти или
                    зарегистрироваться
                  </p>
                </div>

                <Button
                  onClick={() => {
                    dispatch(openModal({ name: "auth" }));
                  }}
                >
                  Регистрация / Вход
                </Button>
              </div>
            </div>
          </>
        ) : !isSubscribed ? (
          <>
            <div className={Style.LoginWrapper}>
              <div className={Style.Login}>
                <div className={Style.LoginContent}>
                  <p className={Style.LoginTitle}>Запишитесь, чтобы начать просмотр</p>
                  {/* <p className={Style.LoginDescription}>
                      Чтобы смотреть трансляцию и общаться в чат, необходимо войти или
                      зарегистрироваться
                    </p> */}
                </div>
                {/* ! Schedule button */}
                <ScheduleButton id={Broadcast?.id} subscribersList={Broadcast?.subscriberList} />
                {/* <Button
                    type="button"
                    color="red"
                    onClick={(e) => {
                      Middleware.Broadcasts.Schedule.subscribe(
                        broadcast.id,
                        broadcast.isSubscribed
                      ).then((response: IBroadcast) => {
                        Middleware.Broadcasts.Selected.patch(response);
                      });
                    }}
                  >
                    {t("broadcast.player.watchBtn")}
                  </Button> */}
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className={Style.Poster}
              onClick={() => {
                setIsVideoPlayed(!isVideoPlayed);
              }}
            />
          </>
        )}
      </div>
      <div className={Style.Chat}>
        {jwt && (
          <Chat broadcast_id={Broadcast?.id} token={jwt} broadcastStatus={currentBroadcastStatus} />
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default BroadcastPlayer;
