import { useAppDispatch, useAppSelector, useForm, useMediaQuery, useQueryParams } from "@hooks";
import { Block, Button, Checkbox, Divider, LabeledControl, Select, SelectItem } from "@components";
import { Collapse } from "@mui/material";
import React, { FC, useEffect } from "react";
import Style from "./Filter.module.scss";
import Models from "@models";
import { fetchProjects } from "@reducers/ProjectsSlice";

interface IProps {
  isFilterShow: boolean;
}

interface IForm {
  region: string;
  specialization: string[];
}

const Filter: FC<IProps> = ({ isFilterShow }) => {
  const dispatch = useAppDispatch();
  const [filterParams, setFilterParams] = useQueryParams<Record<keyof IForm, any>>("filter");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fields, setValue, submitForm, resetFields] = useForm<IForm>({
    region: {
      validators: [],
      value: (filterParams && filterParams.region) ?? "",
    },
    specialization: {
      validators: [],
      value: (filterParams && filterParams.specialization) ?? [],
    },
  });

  const isMd = useMediaQuery("md");
  const regions = useAppSelector((state) => state.Regions.data);
  const specializations = useAppSelector((state) => state.Tags.Specializations.data);

  const checkboxChangeHandler: Models.CheckboxChangeHandler = (e) => {
    const target = e.target as HTMLInputElement;
    const name = target.name as keyof IForm;
    const fieldsValue = fields[name].value;

    if (Array.isArray(fieldsValue)) {
      const tempArr = [...fieldsValue];

      if (tempArr.includes(target.value)) {
        tempArr.splice(tempArr.indexOf(target.value), 1);
      } else {
        tempArr.push(target.value);
      }

      setValue(name, tempArr);
    }
  };

  const selectChangeHandler: Models.SelectChangeHandler = (e) => {
    setValue(e.target.name as keyof IForm, e.target.value);
  };

  useEffect(() => {
    dispatch(
      fetchProjects({ specializations: fields.specialization.value, region: fields.region.value })
    );

    const keys = Object.keys(fields);
    const obj: Record<keyof IForm, any> = {} as IForm;

    keys.map((key) => {
      const value = fields[key as keyof IForm].value;

      if (Array.isArray(value) && value.length) {
        obj[key as keyof IForm] = value;
      } else if (!Array.isArray(value) && value) {
        obj[key as keyof IForm] = value;
      } else {
        return;
      }
    });

    setFilterParams(obj);
  }, [fields]);

  const filter = (
    <>
      <div className={Style.Group}>
        <p className={Style.GroupTitle}>Регион проекта</p>
        <Select
          name={"region"}
          label={"Регион"}
          value={fields?.region.value}
          error={fields?.region.isValid === false}
          helperText={fields?.region.error}
          onChange={selectChangeHandler}
          fullWidth
        >
          {regions?.map((region) => (
            <SelectItem key={region.id} value={region.id}>
              {region.title}
            </SelectItem>
          ))}
        </Select>
      </div>
      <Divider />
      <div className={Style.Group}>
        <p className={Style.GroupTitle}>Тип проекта</p>
        {specializations?.map((specialization) => (
          <LabeledControl
            key={specialization.id}
            name={"specialization"}
            control={
              <Checkbox
                checked={fields.specialization.value.includes(specialization.id.toString())}
                value={specialization.id}
              />
            }
            label={specialization.name}
            onChange={checkboxChangeHandler}
            counter={specialization.count}
          />
        ))}
      </div>
      <Divider />
      <Button
        view="pale"
        endIcon={<i className={"icon-close"} />}
        onClick={() => {
          resetFields();
        }}
      >
        Сбросить фильтр
      </Button>
    </>
  );

  return isMd ? (
    <div className={Style.Root}>
      <div className={Style.Filter}>{filter}</div>
    </div>
  ) : (
    <div className={Style.Root}>
      <Collapse in={isFilterShow || isMd} timeout={isMd ? 0 : "auto"}>
        <div className={Style.Wrapper}>
          <Block className={Style.Filter}>{filter}</Block>
        </div>
      </Collapse>
    </div>
  );
};

export default Filter;
