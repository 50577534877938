import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // import plugin
import duration from "dayjs/plugin/duration"; // import plugin
import timezone from "dayjs/plugin/timezone"; // import plugin
import "dayjs/locale/ru";

dayjs.locale("ru");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

dayjs.tz.setDefault("Europe/Moscow");

export default dayjs;
