import { MenuItem, MenuItemProps } from "@mui/material";
import React, { FC } from "react";
import Style from "./SelectItem.module.scss";

interface IProps extends Partial<MenuItemProps> {}

const SelectItem: FC<IProps> = (props) => {
  const { children } = props;

  return (
    <MenuItem
      className={`${Style.SelectItem} ${Style.Custom}`}
      classes={{ selected: Style.Selected }}
      {...props}
    >
      {children}
    </MenuItem>
  );
};

export default SelectItem;
