import React, { FC } from "react";
import Style from "./About.module.scss";
import headerLogo from "@img/header-logo.svg";
import { Divider } from "@components";
import { HashLink } from "react-router-hash-link";

const About: FC = () => (
  <div className={Style.Root}>
    <img className={Style.Logo} src={headerLogo} />
    <h2 className={Style.Title} id="about">
      Cпециализированный онлайн-форум <br />
      по инвестированию в туристские проекты
    </h2>
    <h3 className={Style.Subtitle}>
      <span>Как инвестировать с максимальной выгодой?</span>
      <Divider className={Style.Divider} />
    </h3>
    <div className={Style.Days}>
      <HashLink
        className={Style.Day}
        to="#20220427"
        scroll={(el: HTMLElement) => el.scrollIntoView({ behavior: "smooth", block: "center" })}
      >
        <div className={Style.DayHeader}>
          <div className={Style.DayDate}>27 апреля</div>
          <Divider className={Style.DayDivider} type="vertical" />
          <time className={Style.DayTime}>
            12:00 <sup>МСК</sup>
          </time>
        </div>
        <div className={Style.DayMain}>
          <h4 className={Style.DayTitle}>
            Конференция о развитии туристской инфраструктуры России
          </h4>
          <ul className={Style.DayList}>
            <li>Перспективы инвестирования в объекты туристской инфраструктуры </li>
            <li>О мерах государственной поддержки инфраструктурных проектов в туризме</li>
            <li>Анализ-обзор приоритетных направлений туризма для инвестиций</li>
            <li>
              Демонстрация механизмов и принципов работы АО «Корпорация Туризм.рф» с инвесторами на
              примере конкретных инвестиционных проектов
            </li>
          </ul>
        </div>
      </HashLink>

      <HashLink
        className={Style.Day}
        to="#20220428"
        scroll={(el: HTMLElement) => el.scrollIntoView({ behavior: "smooth", block: "center" })}
      >
        <div className={Style.DayHeader}>
          <p className={Style.DayDate}>28 апреля</p>
          <Divider className={Style.DayDivider} type="vertical" />
          <time className={Style.DayTime}>
            10:00 <sup>МСК</sup>
          </time>
        </div>
        <div className={Style.DayMain}>
          <h4 className={Style.DayTitle}>Академия инвесторов PIR EXPO</h4>
          <ul className={Style.DayList}>
            <li>Как создать успешный инвестиционный проект в туризме с нуля? </li>
            <li>Разработка бизнес-модели для различных типов объектов туристской инфраструктуры</li>
            <li>Как привлечь инвестиции в свой проект?</li>{" "}
            <li>Ключевые этапы работы над проектом.</li>
          </ul>
        </div>
      </HashLink>
    </div>
  </div>
);

export default About;
