import { FC } from "react";
import Style from "./Footer.module.scss";
import { Divider, Container } from "@components";
import Contact from "./components/Contact";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@hooks";
import logo1 from "@img/footer/logo-1.svg";
import logo2 from "@img/footer/logo-2.svg";
import logo3 from "@img/footer/logo-3.svg";
import privacyPolicy from "@assets/files/privacy_ru.pdf";

interface IContact {
  title: string;
  users: { name: string; email: string }[];
}

const contacts: IContact[] = [
  {
    title: "Кураторы форума",
    users: [
      { name: "Алёна Корнилова", email: "kornilova@profi.travel" },
      { name: "Евгения Скакун", email: "skakun@profi.travel" },
    ],
  },

  {
    title: "По вопросам участия в форуме",
    users: [
      {
        name: "Вера Волкова",
        email: "welcome@profi.travel",
      },
    ],
  },
];

const logos: string[] = [logo1, logo2, logo3];

const Footer: FC = () => {
  const isSm = useMediaQuery("sm");

  return (
    <footer className={Style.Footer}>
      <Container className={Style.Container}>
        <div className={Style.Logos}>
          {logos.map((logo, index: number) => (
            <img key={`logo-${index}`} src={logo} alt="" />
          ))}
        </div>
        <Divider
          className={Style.Divider}
          color="darkgrey"
          type={isSm ? "vertical" : "horizontal"}
        />
        <ul className={Style.Contacts}>
          {contacts.map((item, index) => (
            <Contact title={item.title} users={item.users} key={index} />
          ))}
        </ul>
        {!isSm && <Divider color="darkgrey" />}
        <Link to={privacyPolicy} target="_blank" className={Style.Privacy}>
          Политика обработки данных
        </Link>
      </Container>
    </footer>
  );
};

export default Footer;
