import { useAppDispatch, useAppSelector } from "@hooks";
import { Contacts, Container, Divider } from "@components";
import React, { FC, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Nav from "./components/Nav";
import Projects from "./components/Projects";
import Slider from "./components/Slider";
import Style from "./RegionPage.module.scss";
import { fetchRegion } from "@reducers/RegionSlice";
import RequestStatus from "@utils/RequestStatus";
import { Skeleton } from "@mui/material";
import ScrollToTopOnMount from "@utils/ScrollToTopOnMount";

const RegionPage: FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const region = useAppSelector((state) => state.Region.data);
  const regionStatus = useAppSelector((state) => state.Region.status);
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as { from: string };
  const loading = regionStatus !== RequestStatus.Success;

  useEffect(() => {
    if (id) {
      dispatch(fetchRegion(+id));
    }
  }, []);

  useEffect(() => {
    if (regionStatus === RequestStatus.Error) {
      navigate(locationState?.from ?? "/");
    }
  }, [regionStatus]);

  return (
    <Container type="region" className={Style.Container}>
      <ScrollToTopOnMount />
      <div className={Style.Header}>
        <h1 className={Style.Title}>{loading ? <Skeleton /> : region?.title}</h1>
        <div className={Style.Img}>
          {loading ? (
            <Skeleton height={"100%"} variant="rectangular" />
          ) : (
            <img src={region?.photo} alt="" />
          )}
        </div>
      </div>
      <Nav />
      <div className={Style.Main}>
        <Divider className={Style.Divider} />
        {loading ? (
          <Skeleton variant="rectangular" height={200} />
        ) : (
          <div className={Style.Content} dangerouslySetInnerHTML={{ __html: region?.text ?? "" }} />
        )}

        {loading ? (
          <Skeleton variant="rectangular" height={300} />
        ) : (
          region?.photos?.length && <Slider images={region?.photos} />
        )}

        <Divider className={Style.Divider} />
        {loading ? (
          <>
            <Skeleton variant="rectangular" height={200} />
          </>
        ) : (
          region?.projects?.length && (
            <Projects title="Инвестпроекты региона" projects={region?.projects} />
          )
        )}

        {(region?.website || region?.email || region?.address) && (
          <div className={Style.Footer}>
            <Divider className={Style.Divider} />
            <Contacts
              website={region?.website}
              email={region?.email}
              address={region?.address}
              loading={loading}
            />
          </div>
        )}
      </div>
    </Container>
  );
};

export default RegionPage;
