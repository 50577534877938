import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Models from "@models";
import RequestStatus from "@utils/RequestStatus";
import API from "@api";

interface T {
  [key: string]: Models.Project[];
}
type Response = Models.Response<T>;

const initialState: Models.GenericState<T | null> = {
  data: null,
  status: RequestStatus.Waiting,
  error: "",
};

export const fetchProjects = createAsyncThunk(
  "projects/fetchProjects",
  async (
    data: {
      specializations: string[];
      region: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response: Response = await API.Projects.fetchAll({
        region: data.region ? data.region : null,
        specializations: data.specializations.length ? data.specializations.join(",") : null,
      });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
    }
  }
);

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchProjects.pending.type]: (state) => {
      state.status = RequestStatus.Pending;
    },
    [fetchProjects.fulfilled.type]: (state, action: PayloadAction<Response>) => {
      state.status = RequestStatus.Success;
      state.data = action.payload.data;
    },
    [fetchProjects.rejected.type]: (state, action: PayloadAction<string>) => {
      state.status = RequestStatus.Error;
      state.error = action.payload;
    },
  },
});

export default projectsSlice.reducer;
