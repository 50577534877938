import React, { FC, useState } from "react";
import Style from "./Header.module.scss";
import Logo from "@img/header-logo.svg";
import { Button, Container, Divider } from "@components";
import { IconButton, Button as MUIButton, ClickAwayListener, Slide } from "@mui/material";
import BurgerIcon from "@img/burger.svg";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@hooks";
import { openModal } from "@reducers/UI/ModalsSlice";
import RequestStatus from "@utils/RequestStatus";
import { HashLink } from "react-router-hash-link";
import Services from "@services";

const MenuLinks: { title: string; href: string }[] = [
  { title: "О форуме", href: "/#about" },
  // { title: "Участники форума", href: "#participants" },
  { title: "Деловая программа", href: "/#schedule" },
  { title: "Каталог инвестпроектов", href: "/#catalog" },
];

const Header: FC = () => {
  const dispatch = useAppDispatch();
  const User = useAppSelector((state) => state.User.data);
  const UserStatus = useAppSelector((state) => state.User.status);
  const [isDropdownShow, setIsDropdownShow] = useState<boolean>(false);

  const signOutHandler = async (): Promise<any> => {
    Services.Auth.SignOut().then(() => {
      setIsDropdownShow(false);
    });
  };

  return (
    <>
      <header className={`${Style.Header}`}>
        <Container className={Style.Container}>
          <MUIButton component={Link} to={"/"} className={Style.Logo}>
            <img src={Logo} alt="Туринвест" className={Style.Logo} />
          </MUIButton>

          <ul className={Style.Menu}>
            {MenuLinks.map((item) => (
              <li key={item.title}>
                <MUIButton
                  component={HashLink}
                  to={item.href}
                  className={Style.Item}
                  scroll={(el: HTMLElement) =>
                    el.scrollIntoView({ behavior: "smooth", block: "center" })
                  }
                >
                  {item.title}
                </MUIButton>
              </li>
            ))}
          </ul>

          {!User && UserStatus === RequestStatus.Error && (
            <Button
              onClick={() => {
                dispatch(openModal({ name: "auth" }));
              }}
            >
              Регистрация / Вход
            </Button>
          )}

          <ClickAwayListener
            onClickAway={() => {
              setIsDropdownShow(false);
            }}
          >
            <div className={`${Style.DropdownWrapper} ${!User ? Style.Hidden : ""}`}>
              <Slide direction="left" in={isDropdownShow} mountOnEnter unmountOnExit>
                <div className={Style.Dropdown}>
                  {/* {User && (
                    <>
                      <MUIButton component={Link} to={"#"} className={Style.DropdownItem}>
                        Настройки аккаунта
                      </MUIButton>
                      <Divider />
                    </>
                  )} */}

                  <div className={Style.DropdownGroup}>
                    {MenuLinks.map((item) => (
                      <MUIButton
                        component={HashLink}
                        scroll={(el: HTMLElement) =>
                          el.scrollIntoView({ behavior: "smooth", block: "center" })
                        }
                        to={item.href}
                        key={item.title}
                        className={Style.DropdownItem}
                      >
                        {item.title}
                      </MUIButton>
                    ))}
                  </div>

                  {User && (
                    <>
                      <Divider className={Style.SignOutDivider} />
                      <MUIButton
                        component={Link}
                        to={"#"}
                        className={`${Style.DropdownItem} ${Style.SignOut}`}
                        onClick={() => {
                          signOutHandler();
                        }}
                      >
                        Выйти из аккаунта
                      </MUIButton>
                    </>
                  )}
                </div>
              </Slide>

              <IconButton
                className={Style.Burger}
                onClick={() => {
                  setIsDropdownShow(!isDropdownShow);
                }}
              >
                <img src={BurgerIcon} alt="Меню" />
              </IconButton>

              <MUIButton
                className={Style.UserBtn}
                endIcon={<i className="icon-user" />}
                onClick={() => {
                  setIsDropdownShow(!isDropdownShow);
                }}
              >
                Вы вошли
              </MUIButton>
            </div>
          </ClickAwayListener>
        </Container>
      </header>
    </>
  );
};

export default Header;
