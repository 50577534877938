import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Models from "@models";
import RequestStatus from "@utils/RequestStatus";

interface Modal {
  [key: string]: { isOpen: boolean; inData?: any; outData?: any };
}

const initialState: Models.GenericState<Modal> = {
  data: {},
  status: RequestStatus.Waiting,
  error: "",
};

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<{ name: string; data?: unknown }>) => {
      state.data[action.payload.name] = {
        isOpen: true,
        inData: action.payload.data,
      };
    },
    setModalData: (state, action: PayloadAction<{ name: string; data?: any }>) => {
      state.data[action.payload.name].outData = action.payload.data;
    },
    closeModal: (state, action: PayloadAction<{ name: string; data?: any }>) => {
      state.data[action.payload.name] = { isOpen: false };
    },
    closeAllModals: (state) => {
      Object.keys(state.data).forEach((key) => {
        state.data[key].isOpen = false;
      });
    },
  },
});

export const { openModal, closeModal, setModalData, closeAllModals } = modalsSlice.actions;

export default modalsSlice.reducer;
