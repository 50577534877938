import React, { FC } from "react";
import Style from "./ModalGroup.module.scss";

interface IProps {
  className?: string;
  cols?: 1 | 2;
}

const ModalGroup: FC<IProps> = ({ className, cols, children }) => (
  <div className={`${Style.Group} ${cols === 2 ? Style.GroupCols2 : ""} ${className ?? ""}`}>
    {children}
  </div>
);

export default ModalGroup;
