import { useAppDispatch, useForm } from "@hooks";
import { Button, Input } from "@components";
import React, { FC } from "react";
import Style from "./Email.module.scss";
import Validators from "@utils/Validators";
import Models from "@models";
import API from "@api";
import { useLocation, useNavigate } from "react-router-dom";
import Routes from "@utils/Routes";
import { closeModal } from "@reducers/UI/ModalsSlice";

interface IProps {
  name: string;
  setEmail: React.Dispatch<React.SetStateAction<string | undefined>>;
}

interface IForm {
  email: string;
}

const Email: FC<IProps> = ({ name, setEmail }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [fields, setValue, submitForm] = useForm<IForm>({
    email: {
      validators: [Validators.required(), Validators.email()],
      value: "",
    },
  });

  const changeHandler: Models.InputChangeHandler = (e) => {
    setValue(e.target.name as keyof IForm, e.target.value);
  };

  const formSubmitHandler: Models.SubmitHandler = async (e) => {
    e.preventDefault();
    try {
      submitForm();
      const response = await API.Auth.checkEmail(fields.email.value);

      if (response.data.data.isExist) {
        setEmail(fields.email.value);
      } else {
        dispatch(closeModal({ name }));
        navigate(`${Routes.Auth.SignUp.path}?email=${fields.email.value}`, {
          state: { from: location.pathname },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form className={Style.Form} noValidate onSubmit={formSubmitHandler}>
      <Input
        type="email"
        label="Email"
        name="email"
        error={fields?.email.isValid === false}
        helperText={fields?.email.error}
        fullWidth
        value={fields?.email.value}
        onChange={changeHandler}
      />

      <div className={Style.Footer}>
        <Button type="submit">Далее</Button>
      </div>
    </form>
  );
};

export default Email;
