import React, { FC } from "react";
import Style from "./Block.module.scss";

interface IProps {
  className?: string;
  type?: "broadcast" | "default";
}

const Block: FC<IProps> = ({ children, className, type = "default" }) => {
  const classes = [Style.Root, className];

  switch (type) {
    case "broadcast":
      classes.push(Style.Broadcast);
      break;

    default:
      break;
  }

  return <div className={classes.join(" ")}>{children}</div>;
};

export default Block;
