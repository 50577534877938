import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Models from "@models";
import RequestStatus from "@utils/RequestStatus";
import API from "@api";

type T = Models.Region;
type Response = Models.Response<T>;

const initialState: Models.GenericState<T | null> = {
  data: null,
  status: RequestStatus.Waiting,
  error: "",
};

export const fetchRegion = createAsyncThunk(
  "region/fetchRegion",
  async (id: number, { rejectWithValue }) => {
    try {
      const response: Response = await API.Regions.fetch(id);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
    }
  }
);

const regionSlice = createSlice({
  name: "region",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchRegion.pending.type]: (state) => {
      state.status = RequestStatus.Pending;
    },
    [fetchRegion.fulfilled.type]: (state, action: PayloadAction<Response>) => {
      state.status = RequestStatus.Success;
      state.data = action.payload.data;
    },
    [fetchRegion.rejected.type]: (state, action: PayloadAction<string>) => {
      state.status = RequestStatus.Error;
      state.error = action.payload;
    },
  },
});

// export const { toggleNetworking } = regionSlice.actions;

export default regionSlice.reducer;
